import React, { useContext, useEffect } from "react";
import { Blessure, Hospitalisation, Deces } from "../../components/Icons/Icons";
import CollapsibleCard from "./CollapsibleCard/CollapsibleCard";
import { Row, Col, Form } from "antd";
import styles from "./Tarification.module.css";
import ApplicationFeeInput from "./ApplicationFeeInput/ApplicationFeeInput";
import FormHeader from "../../components/FormHeader/FormHeader";
import GlobalContext from "../../contexts/GlobalContext";
import ExportDevis from "./ExportDevis/ExportDevis";
import dayjs from "dayjs";
import axios from "../../utils/axios";
import SimpleCrypto from "simple-crypto-js";
const Tarification = (props) => {
  const { form } = props;
  const { garantie, globalData, file, setFile, setIdOpp } =
    useContext(GlobalContext);

  const blessureDetails = [
    {
      title:
        "Versement du capital dès la constatation médicale de la blessure sans attendre les suites ou la consolidation.",
    },
    {
      title:
        "Capital forfaitaire non imposable, en complément sans déduction des versements déjà perçus par les organismes sociaux, et libre d’utilisation.",
    },
    {
      title:
        "Barème d’incapacité déjà déterminé, l’intervention d’un médecin expert n’est donc pas nécessaire.",
    },
    {
      title:
        "Couverture des activités du quotidien, professionnelles et privées, partout et jusqu’à 75 ans.",
    },
  ];
  const hospitalisationDetails = [
    {
      title: "Indemnités forfaitaires non imposables et libres d’utilisation.",
    },
    {
      title:
        "Indemnités qui s’ajoutent en complément et sans déduction par les organismes sociaux.",
    },
    {
      title: "Prise en compte des hospitalisations à domicile.",
    },
    {
      title:
        "Couverture des maladies et des accidents, partout et jusqu’à 75 ans.",
    },
  ];
  const décèsDetails = [
    {
      title:
        "Capital forfaitaire non imposable, exonéré des droits de succession, et libre d’utilisation.",
    },
    {
      title: "Capital doublé en cas d’accident de la circulation.",
    },
    {
      title:
        "La garantie vous offre la possibilité de vous protéger ainsi que vos proches en cas d'accidents graves ou mortels.",
    },
    {
      title:
        "Couverture des activités du quotidien, professionnelles et privées, partout et jusqu’à 75 ans.",
    },
  ];
  const tarifications = [
    {
      typeGarantie: "MAA Prévoyance Blessures",
      tarif: "12,75 €",
      cardIcon: <Blessure width="4rem" height="4rem" />,
      details: blessureDetails,
      firstWarningTitle: "",
      firstWarningContent:
        "Un capital forfaitaire vous est versé selon le type de blessure, dès sa constatation médicale, pouvant aller de 1 250 € à 20 000 €.",
      additionalInfo: "",
    },
    {
      typeGarantie: "MAA Prévoyance IJH",
      tarif: "12,75 €",
      cardIcon: <Hospitalisation width="4rem" height="4rem" />,
      details: hospitalisationDetails,
      firstWarningTitle: "",
      firstWarningContent:
        "Une couverture de 40 €/jour dans la limite de 90 jours pour un même événement, avec une franchise de 3 jours",
      additionalInfo: "",
    },
    {
      typeGarantie: "MAA Prévoyance Décès",
      tarif: "5,32 €",
      cardIcon: <Deces width="4rem" height="4rem" />,
      details: décèsDetails,
      firstWarningTitle: "DÉCÈS PAR ACCIDENT ",
      firstWarningContent:
        "Capital forfaitaire versé aux bénéficiaires désignés par le souscripteur",
      secondWarningTitle:
        "INVALIDITÉ ABSOLUE ET DÉFINITIVE (IAD) SUITE À ACCIDENT ",
      secondWarningContent:
        "Capital forfaitaire versé au souscripteur du contrat",
      additionalInfo:
        "Si le décès ou l'état d'IAD est consécutif à un accident dela circulation et que l'indemnisation est due, le capital est doublé.",
      beneficiary: true,
    },
  ];

  useEffect(() => {
    let info_specifiques_ligne_produit = {};
    var tab = [];
    if (globalData?.assurer === "Moi et mes proches") {
      tab = [
        ...tab,
        {
          name: globalData?.nom_conjoint,
          surname: globalData?.prenom_conjoint,
          DN: globalData?.date_naissance_conjoint
            ? dayjs(globalData?.date_naissance_conjoint).format("YYYY-MM-DD")
            : "",
          subs_tag: "Conjoint",
          ville: globalData?._ville,
          adresse_mail: globalData?.email_conjoint,
          civilite: globalData?.civilteConjoint,
          form: {
            data: {
              lieu_naissance: globalData?.lieu_naissance_conjoint,
            },
          },
          CP: globalData?._code_postal,
          streetName: `${globalData["_adresse"]}, France`,
          mobile:
            globalData?.prefixTelConjoint + globalData?.telephone_conjoint,
          relation: "Conjoint",
          pays: globalData?.pays,
        },
      ];
    }
    let offre = {
      info_specifiques_ligne_produit: info_specifiques_ligne_produit,
      prospects: {
        listProspects: tab,
        mainProspect: {
          ville: globalData?._ville,
          adresse_mail: globalData?.souscripteur_email,
          civilite: globalData?.civilite,
          name: globalData?.nom,
          surname: globalData?.prenom,
          DN: dayjs(globalData?.date_naissance).format("YYYY-MM-DD"),
          form: {
            data: {
              lieu_naissance: globalData?.lieuNaissance,
            },
          },
          CP: globalData?._code_postal,
          streetName: `${globalData["_adresse"]}, France`,
          mobile: globalData?.prefixTel + globalData?.téléphone,
          relation: "principal",
          subs_tag: "Prospect",
          pays:"France"
        },
      },
    };
    axios
      .post(`${import.meta.env.VITE_API_URL_AS}offre/add`, offre, {
        headers: {
          idSession: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setIdOpp(res?.data?.id_opportunite_md5);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <div style={{ width: "100%" }}>
      <div className={styles.titleContainer}>
        <FormHeader name={"Un tarif unique sans sélection médicale !"} />
        <div className={styles.tarif}>
          {garantie?.tarif?.toFixed(2)} €
          <span className={styles.subscriptText}>/par mois</span>
        </div>
      </div>
      {garantie?.name === "MAA Prévoyance Pack" ? (
        <Row justify="center">
          {tarifications?.map((tarification) => (
            <Col
              key={tarification.typeGarantie}
              xs={24}
              sm={24}
              md={24}
              lg={24}
            >
              <CollapsibleCard
                typeGarantie={tarification.typeGarantie}
                tarif={tarification.tarif}
                cardIcon={tarification.cardIcon}
                details={tarification.details}
                firstWarningTitle={tarification.firstWarningTitle}
                firstWarningContent={tarification.firstWarningContent}
                secondWarningTitle={tarification.secondWarningTitle}
                secondWarningContent={tarification.secondWarningContent}
                additionalInfo={tarification.additionalInfo}
                garantie={garantie}
                beneficiary={tarification.beneficiary}
                form={form}
              />
            </Col>
          ))}
        </Row>
      ) : garantie?.name === "MAA Prévoyance Blessures" ? (
        <Row justify="center">
          <Col
            key={tarifications[0].typeGarantie}
            xs={24}
            sm={24}
            md={24}
            lg={24}
          >
            <CollapsibleCard
              typeGarantie={tarifications[0].typeGarantie}
              tarif={tarifications[0].tarif}
              cardIcon={tarifications[0].cardIcon}
              details={tarifications[0].details}
              firstWarningTitle={tarifications[0].firstWarningTitle}
              firstWarningContent={tarifications[0].firstWarningContent}
              secondWarningTitle={tarifications[0].secondWarningTitle}
              secondWarningContent={tarifications[0].secondWarningContent}
              additionalInfo={tarifications[0].additionalInfo}
            />
          </Col>
        </Row>
      ) : garantie?.name === "MAA Prévoyance IJH" ? (
        <Row justify="center">
          <Col
            key={tarifications[1].typeGarantie}
            xs={24}
            sm={24}
            md={24}
            lg={24}
          >
            <CollapsibleCard
              typeGarantie={tarifications[1].typeGarantie}
              tarif={tarifications[1].tarif}
              cardIcon={tarifications[1].cardIcon}
              details={tarifications[1].details}
              firstWarningTitle={tarifications[1].firstWarningTitle}
              firstWarningContent={tarifications[1].firstWarningContent}
              secondWarningTitle={tarifications[1].secondWarningTitle}
              secondWarningContent={tarifications[1].secondWarningContent}
              additionalInfo={tarifications[1].additionalInfo}
            />
          </Col>
        </Row>
      ) : garantie?.name === "MAA Prévoyance Décès" ? (
        <Row justify="center">
          <Col
            key={tarifications[2].typeGarantie}
            xs={24}
            sm={24}
            md={24}
            lg={24}
          >
            <CollapsibleCard
              typeGarantie={tarifications[2].typeGarantie}
              tarif={tarifications[2].tarif}
              cardIcon={tarifications[2].cardIcon}
              details={tarifications[2].details}
              firstWarningTitle={tarifications[2].firstWarningTitle}
              firstWarningContent={tarifications[2].firstWarningContent}
              secondWarningTitle={tarifications[2].secondWarningTitle}
              secondWarningContent={tarifications[2].secondWarningContent}
              additionalInfo={tarifications[2].additionalInfo}
              beneficiary={true}
              form={form}
            />
          </Col>
        </Row>
      ) : null}

      {/* <ApplicationFeeInput firstMonthlyPayment={40} applicationFee={35} /> */}
    </div>
  );
};

export default Tarification;

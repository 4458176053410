import React from "react";
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
} from "antd";

import dayjs from "dayjs";
import classes from "./InputComponent.module.css";

const { Search } = Input;

const InputComponent = (props) => {
  const {
    required,
    messageVerificationDate,
    messageRemplissage,
    pattern,
    label,
    name,
    type,
    messageVerification,
    size,
    className,
    controls,
    prefix,
    placeholder,
    addonAfter,
    addonBefore,
    inputType,
    options,
    onBlur,
    form,
    defaultValue,
    radioValues,
    disabledDate,
    minVal,
    maxVal,
    maxDate,
    minDate,
    collg,
    colmd,
    colsm,
    colxs,
    restField,
    checkboxValues,
    namePrefix,
    onSelect,
    handlePrefixChange,
    onchange,
    maxLength,
  } = props;

  const dateFormat = "DD/MM/YYYY";

  const onSearchFunction = (e, name) => {
    onBlur(e, name, form);
  };
  const validRegex = (value, pattern) => {
    const trimmedValue = value ? value.replace(/\s/g, "") : "";
    const regex = new RegExp(pattern);
    return regex.test(trimmedValue);
  };
  return (
    <Col className="starCol" lg={collg} md={colmd} sm={colsm} xs={colxs}>
      {inputType == "input" ? (
        <div>
          <Form.Item
            {...restField}
            name={name}
            rules={[
              {
                required: required,
                message: "",
              },
              {
                validator: (_, value) => {
                  const emptyValue = value ? value.replace(/\s/g, "") : "";
                  if (!emptyValue && required) {
                    return Promise.reject(new Error(messageRemplissage));
                  }
                  if (value) {
                    if (!validRegex(value, pattern)) {
                      return Promise.reject(new Error(messageVerification));
                    }
                  } else if (
                    minVal &&
                    maxVal &&
                    (value < minVal || value > maxVal)
                  ) {
                    return Promise.reject(
                      new Error("Veuillez vérifier ce champ.")
                    );
                  } else if (minDate && value < minDate) {
                    if (
                      name === "date_naissance" ||
                      name === "date_naissance_conjoint"
                    ) {
                      return Promise.reject(
                        new Error(
                          "Veuillez noter que l'âge de souscription pour cette assurance doit être compris entre 18 et 65 ans à compter de la date d'effet de la police."
                        )
                      );
                    } else if (name === "dateEffet") {
                      return Promise.reject(
                        new Error(
                          "Veuillez noter que la date minimale d'effet est fixée à demain."
                        )
                      );
                    } else {
                      return Promise.reject(
                        new Error("Veuillez vérifier ce champ.")
                      );
                    }
                  } else if (maxDate && value > maxDate) {
                    if (
                      name === "date_naissance" ||
                      name === "date_naissance_conjoint"
                    ) {
                      return Promise.reject(
                        new Error(
                          "Veuillez noter que l'âge de souscription pour cette assurance doit être compris entre 18 et 65 ans à compter de la date d'effet de la police."
                        )
                      );
                    } else if (name === "dateEffet") {
                      return Promise.reject(
                        new Error(
                          "Veuillez noter que la date d'effet maximale est d'un mois à partir d'aujourd'hui."
                        )
                      );
                    } else {
                      return Promise.reject(
                        new Error("Veuillez vérifier ce champ.")
                      );
                    }
                  }
                  return Promise.resolve();
                },
              },
            ]}
            className="star"
            label={<label className={className}>{label}</label>}
          >
            <Input
              disabled={name === "pays"}
              max={maxDate}
              min={minDate}
              onChange={onchange}
              onBlur={(e) => {
                if (onBlur) onSearchFunction(e.target.value, name);
              }}
              addonBefore={addonBefore}
              addonAfter={addonAfter}
              placeholder={placeholder}
              value={defaultValue}
              prefix={prefix}
              controls={controls}
              type={type}
              maxLength={maxLength}
              style={{
                backgroundColor:
                  name === "dateEffet"
                    ? "#F2FAFF"
                    : name === "pays"
                    ? "#F2F3F4"
                    : "white",
                border:
                  name === "dateEffet"
                    ? "2px solid #D2EEFF"
                    : name === "pays"
                    ? " 2px solid #E7E7E7"
                    : "",
                textAlign: name === "dateEffet" ? "center" : "start",
              }}
            />
          </Form.Item>
        </div>
      ) : inputType === "phone" ? (
        <Form.Item
          name="inputphone"
          rules={[
            {
              required: required,
              message: "",
            },
          ]}
          label={<label className={className}>{label}</label>}
        >
          <Space.Compact compact="true" style={{ display: "flex" }}>
            <Form.Item
              name={namePrefix}
              style={{ flex: 1 }}
              initialValue={defaultValue}
              rules={[
                {
                  required: required,
                  message: messageRemplissage,
                },
                {
                  pattern: new RegExp(/^\+\d+$/),
                  message: "Veuillez vérifier ce champ.",
                },
              ]}
            >
              <Input
                style={{ borderRight: "white" }}
                onChange={handlePrefixChange}
              />
            </Form.Item>
            <Form.Item
              name={name}
              rules={[
                {
                  required: required,
                  message: messageRemplissage,
                },
                ({ getFieldValue }) => ({
                  pattern: new RegExp(
                    getFieldValue(namePrefix).replace(/\s/g, "") === "+33" ||
                    getFieldValue(namePrefix).replace(/\s/g, "") === "0033"
                      ? pattern
                      : /^[0-9]*$/
                  ),
                  message: messageVerification,
                }),
              ]}
              style={{ flex: 5 }}
            >
              <Input addonAfter={addonAfter} onChange={onchange} />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
      ) : inputType === "select" ? (
        <Form.Item
          name={name}
          rules={[
            {
              required: required,
              message: messageRemplissage,
            },
          ]}
          label={<label className={className}>{label}</label>}
        >
          <Select
            // onChange={(e) => onChangeFunction(e, "select")}
            onSelect={onSelect}
            options={options && options.length !== 0 ? options : ""}
            placeholder={placeholder}
          />
        </Form.Item>
      ) : inputType === "search" ? (
        <Form.Item
          rules={rules}
          name={name}
          label={<label className={className}>{label}</label>}
        >
          <Search
            type={type}
            placeholder="exp: 1245893677"
            size="large"
            onSearch={(e) => onSearchFunction(e, name)}
          />
        </Form.Item>
      ) : inputType === "date" ? (
        <Form.Item
          label={<label className={className}>{label}</label>}
          name={name}
          rules={[
            {
              required: required,
              message: messageRemplissage,
            },
            {
              validator: (_, value) => {
                if (value) {
                  if (name === "dateEffet") {
                    if (
                      value.format("YYYY-MM-DD") < minDate.format("YYYY-MM-DD")
                    ) {
                      return Promise.reject(
                        new Error(
                          "Veuillez noter que la date minimale d'effet est fixée à demain."
                        )
                      );
                    } else if (
                      value.format("YYYY-MM-DD") > maxDate.format("YYYY-MM-DD")
                    ) {
                      return Promise.reject(
                        new Error(
                          "Veuillez noter que la date d'effet maximale est d'un mois à partir d'aujourd'hui."
                        )
                      );
                    }
                  } else {
                    const effetDate = form.getFieldsValue()["dateEffet"];
                    const yearEffet = dayjs(effetDate).year();
                    const birthYear = value.year();
                    const age = yearEffet - birthYear;
                    if (age < minDate || age > maxDate) {
                      {
                        return Promise.reject(
                          new Error(messageVerificationDate)
                        );
                      }
                    }
                  }
                }

                return Promise.resolve();
              },
            },
          ]}
          className={name === "dateEffet" ? "dateEffetPicker" : ""}
        >
          <DatePicker
            className={name === "dateEffet" ? classes.dateEffet : ""}
            size="large"
            placeholder="jj/mm/aaaa"
            format={dateFormat}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Form.Item>
      ) : inputType === "checkbox" ? (
        <Form.Item
          name={name}
          rules={[
            {
              required: required,
              message: messageRemplissage,
            },
          ]}
          label={<label className={classes.labelStyle}>{label}</label>}
        >
          <Checkbox.Group>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100vh",
              }}
            >
              {checkboxValues?.map((checkboxValue, index) => (
                <Col key={index}>
                  <Checkbox
                    value={checkboxValue}
                    className={classes.checkboxText}
                    // checked={defaultvalue}
                  >
                    {checkboxValue}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      ) : (
        <Form.Item
          name={name}
          label={<label>{label}</label>}
          rules={[
            {
              required: required,
              message: messageRemplissage,
            },
          ]}
        >
          <Radio.Group className={classes.rowFlex}>
            <Row
              gutter={[16, 16]}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {radioValues?.map((value, index) => (
                <Col key={index}>
                  {value.card ? (
                    <CardsComponent
                      image={value.cardImage}
                      name={value.name}
                      form={form}
                    />
                  ) : null}
                  <Radio
                    value={value.name}
                    key={index}
                    onChange={() => handleCardClick(value.name)}
                    className={classes.labelStyle}
                  >
                    {value.name}
                  </Radio>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </Form.Item>
      )}
    </Col>
  );
};

export default InputComponent;

import { useContext, useEffect, useState } from "react";

import classes from "./Home.module.css";
import {
  message,
  Steps,
  Form,
  ConfigProvider,
  Row,
  Col,
  notification,
  FloatButton,
} from "antd";
import Header from "../../components/Header/Header";
import InformationsSouscripteur from "../InformationsSouscripteur/InformationsSouscripteur";
import Couvertures from "../Couvertures/Couvertures";
import InformationsComplementaires from "../InformationsComplementaires/InformationsComplementaires";
import Paiement from "../Paiement/Paiement";
import Tarification from "../Tarification/Tarification";
import GlobalContext from "../../contexts/GlobalContext";
import ModalSuccess from "../Paiement/ModalSuccess/ModalSuccess";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import { ClearOutlined } from "@ant-design/icons";
import axios from "../../utils/axios.jsx";
import dayjs from "dayjs";
import { CheckCircleOutlined } from "@ant-design/icons";
import ModalFail from "../Paiement/ModalFail/ModalFail";
import ExportDevis from "../Tarification/ExportDevis/ExportDevis";
import SimpleCrypto from "simple-crypto-js";
import template from "../../constants/template_devis.js";
import frFR from "antd/locale/fr_FR";
function Home() {
  const {
    brandProperties,
    garantie,
    setCouverturesErr,
    setGlobalData,
    globalData,
    setFile,
    idOpp,
    current,
    setCurrent,
    file,
    validRef,
    entreprise,
    form,
    id,
    userData,
    subscribeEnabled,
    setSubscribeEnabled,
    partnership_id,
    garantieOptions,
    setGarantieOptions,
  } = useContext(GlobalContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingSouscription, setIsLoadingSousciption] = useState(false);
  const [modalfail, setMOdalFail] = useState("");
  const [parTel, setParTel] = useState(false);
  const [parMail, setParMail] = useState(false);
  const [parCourtier, setParCourtier] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [villes, setVilles] = useState([]);
  const steps = [
    {
      title: "Informations principales",
      content: (
        <InformationsSouscripteur
          form={form}
          villes={villes}
          setVilles={setVilles}
        />
      ),
    },
    {
      title: "Informations complémentaires",
      content: (
        <InformationsComplementaires
          garantieOptions={garantieOptions}
          setGarantieOptions={setGarantieOptions}
          parTel={parTel}
          setParTel={setParTel}
          parMail={parMail}
          setParMail={setParMail}
          parCourtier={parCourtier}
          setParCourtier={setParCourtier}
        />
      ),
    },
    {
      title: "Garanties",
      content: (
        <Couvertures
          form={form}
          setMOdalFail={setMOdalFail}
          modalfail={modalfail}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      ),
    },

    {
      title: "Tarification",
      content: <Tarification form={form} />,
    },

    {
      title: "Paiement",
      content: <Paiement form={form} />,
    },
  ];
  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  const next = () => {
    if (current === 3 && (!garantie || garantie?.length === 0)) {
      setCouverturesErr(true);
    } else if (
      current === 2 &&
      garantieOptions["mesure_curatelle_tutelle"] === true &&
      garantieOptions["politiquement_exposée"] === false
    ) {
      notification.warning({
        className: classes.warning,
        message: "Warning",
        description:
          "Non-éligibilité en raison d'une mesure de Curatelle ou de Tutelle.",
      });
    } else if (
      current === 2 &&
      garantieOptions["politiquement_exposée"] === true &&
      garantieOptions["mesure_curatelle_tutelle"] === false
    ) {
      notification.warning({
        className: classes.warning,
        message: "Warning",
        description:
          "Nous regrettons de vous informer que nous ne pouvons pas assurer les personnes politiquement exposées (PPE) pour le moment.",
      });
    } else if (
      current === 2 &&
      garantieOptions["mesure_curatelle_tutelle"] === true &&
      garantieOptions["politiquement_exposée"] === true
    ) {
      notification.warning({
        className: classes.warning,
        message: "Warning",
        description:
          "Non-éligibilité en raison d'une mesure de Curatelle ou de Tutelle.",
      });

      setTimeout(() => {
        notification.warning({
          className: classes.warning,
          message: "Attention",
          description:
            "Nous regrettons de vous informer que nous ne pouvons pas assurer les personnes politiquement exposées (PPE) pour le moment.",
        });
      }, 400);
    } else if (current === 5) {
      handleSouscription();
    } else {
      notification.destroy();
      setCouverturesErr(false);
      setCurrent(current + 1);
      saveSession();
    }
  };

  const hideModal = () => {
    setMOdalFail("");
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const saveSession = () => {
    let x = {
      ...form.getFieldsValue(),
    };
    if (current === 1) {
      x = {
        ...x,
        dateEffet: dayjs(x.dateEffet).format("DD/MM/YYYY"),
        date_naissance: dayjs(x.date_naissance).format("DD/MM/YYYY"),
      };
      if (globalData?.assurer === "Moi et mes proches") {
        x.date_naissance_conjoint = dayjs(x.date_naissance_conjoint).format(
          "DD/MM/YYYY"
        );
      }
    } else {
      x = {
        ...globalData,
        ...x,
      };
    }
    let value = {
      ...globalData,
      ...form.getFieldsValue(),
    };
    sessionStorage.setItem("globalData", JSON.stringify(value));
    setGlobalData(value);
  };
  useEffect(() => {
    sessionStorage.setItem("garantieOptions", JSON.stringify(garantieOptions));
  }, [garantieOptions]);
  useEffect(() => {
    form.setFieldsValue(
      JSON.parse(sessionStorage.getItem("globalData"))
        ? {
            ...JSON.parse(sessionStorage.getItem("globalData")),
            methodePaiement: JSON.parse(sessionStorage.getItem("globalData"))
              ?.methodePaiement
              ? JSON.parse(sessionStorage.getItem("globalData"))
                  ?.methodePaiement
              : "Mandat SEPA",

            assurer: JSON.parse(sessionStorage.getItem("globalData"))?.assurer
              ? JSON.parse(sessionStorage.getItem("globalData"))?.assurer
              : "Moi seulement",
            dateEffet: JSON.parse(sessionStorage.getItem("globalData"))
              ?.dateEffet
              ? dayjs(
                  JSON.parse(sessionStorage.getItem("globalData"))?.dateEffet
                )
              : dayjs().add(1, "day"),
            date_naissance: JSON.parse(sessionStorage.getItem("globalData"))
              ?.date_naissance
              ? dayjs(
                  JSON.parse(sessionStorage.getItem("globalData"))
                    ?.date_naissance
                )
              : "",
            date_naissance_conjoint: JSON.parse(
              sessionStorage.getItem("globalData")
            )?.date_naissance_conjoint
              ? dayjs(
                  JSON.parse(sessionStorage.getItem("globalData"))
                    ?.date_naissance_conjoint
                )
              : "",
            type_fractionnement: JSON.parse(
              sessionStorage.getItem("globalData")
            )?.type_fractionnement
              ? JSON.parse(sessionStorage.getItem("globalData"))
                  ?.type_fractionnement
              : "Mensuel",
          }
        : {
            ...form.getFieldsValue(),
            methodePaiement: JSON.parse(sessionStorage.getItem("globalData"))
              ?.methodePaiement
              ? JSON.parse(sessionStorage.getItem("globalData"))
                  ?.methodePaiement
              : "Mandat SEPA",
            assurer: JSON.parse(sessionStorage.getItem("globalData"))?.assurer
              ? JSON.parse(sessionStorage.getItem("globalData"))?.assurer
              : "Moi seulement",
            dateEffet: dayjs().add(1, "day"),
            type_fractionnement: "Mensuel",
          }
    );

    setGlobalData(JSON.parse(sessionStorage.getItem("globalData")));
  }, []);

  useEffect(() => {
    sessionStorage.setItem("current", JSON.stringify(current));
  }, [current]);

  useEffect(() => {
    if (validRef) {
      form.setFieldsValue({
        ...globalData,
        dateEffet: dayjs(globalData?.dateEffet),
        date_naissance: dayjs(globalData?.date_naissance),
        date_naissance_conjoint: globalData?.date_naissance_conjoint
          ? dayjs(globalData?.date_naissance_conjoint)
          : "",
      });
    }
  }, [validRef]);
  const handleSouscription = () => {
    if (partnership_id) {
      setIsLoadingSousciption(true);
      let config = {
        headers: {
          apiKey: partnership_id,
        },
      };

      let dataFiche = {
        nom_prenom_courtier: entreprise?.nomEntreprise,
        nom_prenom_conseiller: userData?.nom + " " + userData?.prenom,
        adresse_correspandance: entreprise?.adresseEntreprise
          ? entreprise?.adresseEntreprise
          : "",
        rcs: entreprise?.rcs ? entreprise?.rcs : "",
        n_orias: entreprise?.orias ? entreprise?.orias : "",
        adresse_siege: entreprise?.adresseEntreprise
          ? entreprise?.adresseEntreprise
          : "",
        salarie: "True",
        nom_client: globalData["nom"],
        prenom_client: globalData["prenom"],
        cp_client: globalData["_code_postal"],
        ville_client: globalData["_ville"],
        email_client: globalData["souscripteur_email"],
        telmobile_client: `${globalData.prefixTel} ${globalData["téléphone"]}`,
        montant_annuelle: (garantie?.tarif * 12)?.toFixed(2),

        telfixe_client: `${globalData.prefixTel} ${globalData["téléphone"]}`,
        dn_client: dayjs(globalData["date_naissance"], "DD/MM/YYYY").format(
          "DD/MM/YYYY"
        ),
        politiquement_exposee_oui:
          garantieOptions["politiquement_exposée"] === true
            ? "True"
            : "False",
        politiquement_exposee_non:
          garantieOptions["politiquement_exposée"] === false
            ? "True"
            : "False",
        mesure_curatelle_tutelle_oui:
          garantieOptions["mesure_curatelle_tutelle"] === true
            ? "True"
            : "False",
        mesure_curatelle_tutelle_non:
          garantieOptions["mesure_curatelle_tutelle"] === false
            ? "True"
            : "False",
        papiers_aide_ext_oui:
          garantieOptions["papiers_sans_aide_ext"] === true
            ? "True"
            : "False",
        papiers_aide_ext_non:
          garantieOptions["papiers_sans_aide_ext"] === false
            ? "True"
            : "False",
        situation_familiale: "",
        nbre_enfant: "",
        accidents_corpoerls_oui:
          garantieOptions["conséquences_accidents_corporels"] === true
            ? "True"
            : "False",
        accidents_corpoerls_non:
          garantieOptions["conséquences_accidents_corporels"] === false
            ? "True"
            : "False",
        conseq_graves_oui:
          garantieOptions["accidents_corporels_conséquences_graves"] ===
          true
            ? "True"
            : "False",
        conseq_graves_non:
          garantieOptions["accidents_corporels_conséquences_graves"] ===
          false
            ? "True"
            : "False",
        garantie_indemnite_oui:
          garantieOptions["indemnité_journalière_cas_hospitalisation"] ===
          true
            ? "True"
            : "False",
        garantie_indemnite_non:
          garantieOptions["indemnité_journalière_cas_hospitalisation"] ===
          false
            ? "True"
            : "False",
        versement_capital_oui:
          garantieOptions["versement_capital_forfaitaire"] === true
            ? "True"
            : "False",
        versement_capital_non:
          garantieOptions["versement_capital_forfaitaire"] === false
            ? "True"
            : "False",
        accident_5_derniers_annee_oui:
          garantieOptions["subi_accident_cinq_dernières_années"] === true
            ? "True"
            : "False",
        accident_5_derniers_annee_non:
          garantieOptions["subi_accident_cinq_dernières_années"] === false
            ? "True"
            : "False",
        hospitalisation_programmee_oui:
          garantieOptions["hospitalisation_programmée"] === true
            ? "True"
            : "False",
        hospitalisation_programmee_non:
          garantieOptions["hospitalisation_programmée"] === false
            ? "True"
            : "False",
        accidents_corpoerls_conjoint_oui:
          garantieOptions["conséquences_accidents_corporels_conjoint"] ===
          true
            ? "True"
            : "False",
        accidents_corpoerls_conjoint_non:
          garantieOptions["conséquences_accidents_corporels_conjoint"] ===
          false
            ? "True"
            : "False",
        conseq_graves_conjoint_oui:
          garantieOptions[
            "accidents_corporels_conséquences_graves_conjoint"
          ] === true
            ? "True"
            : "False",
        conseq_graves_conjoint_non:
          garantieOptions[
            "accidents_corporels_conséquences_graves_conjoint"
          ] === false
            ? "True"
            : "False",
        garantie_indemnite_conjoint_oui:
          garantieOptions[
            "indemnité_journalière_cas_hospitalisation_conjoint"
          ] === true
            ? "True"
            : "False",
        garantie_indemnite_conjoint_non:
          garantieOptions[
            "indemnité_journalière_cas_hospitalisation_conjoint"
          ] === false
            ? "True"
            : "False",
        versement_capital_conjoint_oui:
          garantieOptions["versement_capital_forfaitaire_conjoint"] === true
            ? "True"
            : "False",
        versement_capital_conjoint_non:
          garantieOptions["versement_capital_forfaitaire_conjoint"] ===
          false
            ? "True"
            : "False",
        accident_5_derniers_annee_conjoint_oui:
          garantieOptions[
            "subi_accident_cinq_dernières_années_conjoint"
          ] === true
            ? "True"
            : "False",
        accident_5_derniers_annee_conjoint_non:
          garantieOptions[
            "subi_accident_cinq_dernières_années_conjoint"
          ] === false
            ? "True"
            : "False",
        hospitalisation_programmee_conjoint_oui:
          garantieOptions["hospitalisation_programmée_conjoint"] === true
            ? "True"
            : "False",
        hospitalisation_programmee_conjoint_non:
          garantieOptions["hospitalisation_programmée_conjoint"] === false
            ? "True"
            : "False",
        electronique: "True",
        manuscrite: "False",
        par_tel_oui: globalData?.type_envoi?.includes("Par téléphone")
          ? "True"
          : "False",
        par_tel_non: globalData?.type_envoi?.includes("Par téléphone")
          ? "False"
          : "True",
        par_mail_oui: globalData?.type_envoi?.includes("Par Email")
          ? "True"
          : "False",
        par_mail_non: globalData?.type_envoi?.includes("Par Email")
          ? "False"
          : "True",
        par_courtier_oui: globalData?.type_envoi?.includes("Par courrier")
          ? "True"
          : "False",
        par_courtier_non: globalData?.type_envoi?.includes("Par courrier")
          ? "False"
          : "True",
        nom_prenom_assure: globalData["nom"] + " " + globalData["prenom"],
        date: dayjs().format("DD/MM/YYYY"),
        lieu: globalData["_ville"],
        nom_conseiller: "",
        // contrat: response.data.num_contrat,
      };
      let payloadEditique = {
        idModel:
          garantie?.name === "MAA Prévoyance Décès"
            ? import.meta.env.VITE_API_FICHE_CONSEIL_MODEL_DECES
            : garantie?.name === "MAA Prévoyance Pack"
            ? import.meta.env.VITE_API_FICHE_CONSEIL_MODEL_TROIS_GARANTIES
            : garantie?.name === "MAA Prévoyance IJH"
            ? import.meta.env.VITE_API_FICHE_CONSEIL_MODEL_HOSPITALISATION
            : import.meta.env.VITE_API_FICHE_CONSEIL_MODEL_BLESSURE,
        data: dataFiche,
      };
      

      // if (globalData["assurer"] !== "Moi et mes proches") {
      //   payloadEditique["deletePages"] = [1];
      // }



      const data = {
        ficher_conseil_print_data : payloadEditique,
        id_opp: idOpp ? idOpp : "",
        politiquement_exposee:
          garantieOptions["politiquement_exposée"] === true ? true : false,
        pack:
          garantie?.name === "MAA Prévoyance Décès"
            ? "DECES"
            : garantie?.name === "MAA Prévoyance Pack"
            ? "COMPLET"
            : garantie?.name === "MAA Prévoyance IJH"
            ? "HOSPITALISATION"
            : "BLESSURE",
        information_bancaire: {
          bic_swift: form.getFieldsValue()["BICremb"]
            ? form.getFieldsValue()["BICremb"]
            : form.getFieldsValue()["BICprelev"],
          iban: form.getFieldsValue()["IBANremb"]
            ? form.getFieldsValue()["IBANremb"]
            : form.getFieldsValue()["IBANprelev"],
          type_iban: "prestation",
          titulaire_compte: form.getFieldsValue()[
            "Nom & Prénom titulaire du compteremb"
          ]
            ? form.getFieldsValue()["Nom & Prénom titulaire du compteremb"]
            : form.getFieldsValue()["Nom & Prénom titulaire du compteprelev"],
          nom_banque: form.getFieldsValue()["Nom de la banqueremb"]
            ? form.getFieldsValue()["Nom de la banqueremb"]
            : form.getFieldsValue()["Nom de la banqueprelev"],
        },
        pricing: {
          frais_dossier: 0.0,
          customer_information: [
            {
              primary_subscriber: true,
              birth_info: {
                date: dayjs(globalData["date_naissance"]).format("DD/MM/YYYY"),
                address: globalData?.lieuNaissance,
              },
              address: {
                street_name: `${globalData["_adresse"]}, France`,
                zip_code: globalData["_code_postal"],
                city: globalData["_ville"],
              },

              mail: globalData["souscripteur_email"],
              identity: {
                civility: globalData?.civilite,
                use_name: globalData["nom"],
                first_name: globalData["prenom"],
              },
              phone: [
                {
                  type: "Mobile",
                  country_prefix: globalData.prefixTel,
                  number: globalData["téléphone"],
                },
              ],
            },
          ],
          contract_information: {
            effective_date: dayjs(globalData?.dateEffet).format("DD/MM/YYYY"),
            mode_splitting: form.getFieldsValue()["type_fractionnement"],
            payment_information: {
              mode_splitting: form.getFieldsValue()["type_fractionnement"],
              withdrawal_day:
                form.getFieldsValue()["methodePaiement"] === "Carte Bancaire"
                  ? dayjs(globalData?.dateEffet).date().toString()
                  : form.getFieldsValue()["Jour de prélèvement"],
              payment_mode:
                form.getFieldsValue()["methodePaiement"] === "Carte Bancaire"
                  ? "Card"
                  : "Direct Debit",
              bic: form.getFieldsValue()["BICprelev"],
              iban: form.getFieldsValue()["IBANprelev"],
              nom_banque: form.getFieldsValue()["Nom de la banqueprelev"],
              nom_prenom_debiteur:
                form.getFieldsValue()["Nom & Prénom titulaire du compteprelev"],
            },
          },
        },
      };

      // if (form.getFieldsValue()["methodePaiement"] != "Carte Bancaire") {
      //   data["pricing"]["contract_information"]["payment_information"]["withdrawal_day"] = form.getFieldsValue()["Jour de prélèvement"];
      // }

      if (globalData["assurer"] === "Moi et mes proches") {
        data.pricing.customer_information = [
          ...data.pricing.customer_information,
          {
            primary_subscriber: false,
            // insured: true,
            // beneficiary: false,
            birth_info: {
              date: dayjs(globalData["date_naissance_conjoint"]).format(
                "DD/MM/YYYY"
              ),
              address: globalData?.lieu_naissance_conjoint,
            },
            address: {
              street_name: `${globalData["_adresse"]}, France`,
              zip_code: globalData["_code_postal"],
              city: globalData["_ville"],
            },
            // relationship: "married",
            mail: globalData["email_conjoint"],
            identity: {
              civility: globalData?.civilteConjoint ,
              use_name: globalData["nom_conjoint"],
              first_name: globalData["prenom_conjoint"],
            },

            phone: [
              {
                type: "Mobile",
                country_prefix: globalData?.prefixTelConjoint.toString(),
                number: globalData["telephone_conjoint"],
              },
            ],
          },
        ];
      }

      

      axios
        .post(
          `${import.meta.env.VITE_API_URL_AS}tarification_maa/souscription_maa`,
          data,
          config
        )
        .then((response) => { 
          setIsModalOpen(true);
          setIsLoadingSousciption(false);

          // if (response.data.error === false) {
          // let dataFiche = {
          //   nom_prenom_courtier: entreprise?.nomEntreprise,
          //   nom_prenom_conseiller: userData?.nom + " " + userData?.prenom,
          //   adresse_correspandance: entreprise?.adresseEntreprise
          //     ? entreprise?.adresseEntreprise
          //     : "",
          //   rcs: entreprise?.rcs ? entreprise?.rcs : "",
          //   n_orias: entreprise?.orias ? entreprise?.orias : "",
          //   adresse_siege: entreprise?.adresseEntreprise
          //     ? entreprise?.adresseEntreprise
          //     : "",
          //   salarie: "True",
          //   nom_client: globalData["nom"],
          //   prenom_client: globalData["prenom"],
          //   cp_client: globalData["_code_postal"],
          //   ville_client: globalData["_ville"],
          //   email_client: globalData["souscripteur_email"],
          //   telmobile_client: `${globalData.prefixTel} ${globalData["téléphone"]}`,
          //   montant_annuelle: (garantie?.tarif * 12)?.toFixed(2),

          //   telfixe_client: `${globalData.prefixTel} ${globalData["téléphone"]}`,
          //   dn_client: dayjs(globalData["date_naissance"], "DD/MM/YYYY").format(
          //     "DD/MM/YYYY"
          //   ),
          //   politiquement_exposee_oui:
          //     garantieOptions["politiquement_exposée"] === true
          //       ? "True"
          //       : "False",
          //   politiquement_exposee_non:
          //     garantieOptions["politiquement_exposée"] === false
          //       ? "True"
          //       : "False",
          //   mesure_curatelle_tutelle_oui:
          //     garantieOptions["mesure_curatelle_tutelle"] === true
          //       ? "True"
          //       : "False",
          //   mesure_curatelle_tutelle_non:
          //     garantieOptions["mesure_curatelle_tutelle"] === false
          //       ? "True"
          //       : "False",
          //   papiers_aide_ext_oui:
          //     garantieOptions["papiers_sans_aide_ext"] === true
          //       ? "True"
          //       : "False",
          //   papiers_aide_ext_non:
          //     garantieOptions["papiers_sans_aide_ext"] === false
          //       ? "True"
          //       : "False",
          //   situation_familiale: "",
          //   nbre_enfant: "",
          //   accidents_corpoerls_oui:
          //     garantieOptions["conséquences_accidents_corporels"] === true
          //       ? "True"
          //       : "False",
          //   accidents_corpoerls_non:
          //     garantieOptions["conséquences_accidents_corporels"] === false
          //       ? "True"
          //       : "False",
          //   conseq_graves_oui:
          //     garantieOptions["accidents_corporels_conséquences_graves"] ===
          //     true
          //       ? "True"
          //       : "False",
          //   conseq_graves_non:
          //     garantieOptions["accidents_corporels_conséquences_graves"] ===
          //     false
          //       ? "True"
          //       : "False",
          //   garantie_indemnite_oui:
          //     garantieOptions["indemnité_journalière_cas_hospitalisation"] ===
          //     true
          //       ? "True"
          //       : "False",
          //   garantie_indemnite_non:
          //     garantieOptions["indemnité_journalière_cas_hospitalisation"] ===
          //     false
          //       ? "True"
          //       : "False",
          //   versement_capital_oui:
          //     garantieOptions["versement_capital_forfaitaire"] === true
          //       ? "True"
          //       : "False",
          //   versement_capital_non:
          //     garantieOptions["versement_capital_forfaitaire"] === false
          //       ? "True"
          //       : "False",
          //   accident_5_derniers_annee_oui:
          //     garantieOptions["subi_accident_cinq_dernières_années"] === true
          //       ? "True"
          //       : "False",
          //   accident_5_derniers_annee_non:
          //     garantieOptions["subi_accident_cinq_dernières_années"] === false
          //       ? "True"
          //       : "False",
          //   hospitalisation_programmee_oui:
          //     garantieOptions["hospitalisation_programmée"] === true
          //       ? "True"
          //       : "False",
          //   hospitalisation_programmee_non:
          //     garantieOptions["hospitalisation_programmée"] === false
          //       ? "True"
          //       : "False",
          //   accidents_corpoerls_conjoint_oui:
          //     garantieOptions["conséquences_accidents_corporels_conjoint"] ===
          //     true
          //       ? "True"
          //       : "False",
          //   accidents_corpoerls_conjoint_non:
          //     garantieOptions["conséquences_accidents_corporels_conjoint"] ===
          //     false
          //       ? "True"
          //       : "False",
          //   conseq_graves_conjoint_oui:
          //     garantieOptions[
          //       "accidents_corporels_conséquences_graves_conjoint"
          //     ] === true
          //       ? "True"
          //       : "False",
          //   conseq_graves_conjoint_non:
          //     garantieOptions[
          //       "accidents_corporels_conséquences_graves_conjoint"
          //     ] === false
          //       ? "True"
          //       : "False",
          //   garantie_indemnite_conjoint_oui:
          //     garantieOptions[
          //       "indemnité_journalière_cas_hospitalisation_conjoint"
          //     ] === true
          //       ? "True"
          //       : "False",
          //   garantie_indemnite_conjoint_non:
          //     garantieOptions[
          //       "indemnité_journalière_cas_hospitalisation_conjoint"
          //     ] === false
          //       ? "True"
          //       : "False",
          //   versement_capital_conjoint_oui:
          //     garantieOptions["versement_capital_forfaitaire_conjoint"] === true
          //       ? "True"
          //       : "False",
          //   versement_capital_conjoint_non:
          //     garantieOptions["versement_capital_forfaitaire_conjoint"] ===
          //     false
          //       ? "True"
          //       : "False",
          //   accident_5_derniers_annee_conjoint_oui:
          //     garantieOptions[
          //       "subi_accident_cinq_dernières_années_conjoint"
          //     ] === true
          //       ? "True"
          //       : "False",
          //   accident_5_derniers_annee_conjoint_non:
          //     garantieOptions[
          //       "subi_accident_cinq_dernières_années_conjoint"
          //     ] === false
          //       ? "True"
          //       : "False",
          //   hospitalisation_programmee_conjoint_oui:
          //     garantieOptions["hospitalisation_programmée_conjoint"] === true
          //       ? "True"
          //       : "False",
          //   hospitalisation_programmee_conjoint_non:
          //     garantieOptions["hospitalisation_programmée_conjoint"] === false
          //       ? "True"
          //       : "False",
          //   electronique: "True",
          //   manuscrite: "False",
          //   par_tel_oui: globalData?.type_envoi?.includes("Par téléphone")
          //     ? "True"
          //     : "False",
          //   par_tel_non: globalData?.type_envoi?.includes("Par téléphone")
          //     ? "False"
          //     : "True",
          //   par_mail_oui: globalData?.type_envoi?.includes("Par Email")
          //     ? "True"
          //     : "False",
          //   par_mail_non: globalData?.type_envoi?.includes("Par Email")
          //     ? "False"
          //     : "True",
          //   par_courtier_oui: globalData?.type_envoi?.includes("Par courrier")
          //     ? "True"
          //     : "False",
          //   par_courtier_non: globalData?.type_envoi?.includes("Par courrier")
          //     ? "False"
          //     : "True",
          //   nom_prenom_assure: globalData["nom"] + " " + globalData["prenom"],
          //   date: dayjs().format("DD/MM/YYYY"),
          //   lieu: globalData["_ville"],
          //   nom_conseiller: "",
          //   contrat: response.data.num_contrat,
          // };
          // let payloadEditique = {
          //   idModel:
          //     garantie?.name === "MAA Prévoyance Décès"
          //       ? import.meta.env.VITE_API_FICHE_CONSEIL_MODEL_DECES
          //       : garantie?.name === "MAA Prévoyance Pack"
          //       ? import.meta.env.VITE_API_FICHE_CONSEIL_MODEL_TROIS_GARANTIES
          //       : garantie?.name === "MAA Prévoyance IJH"
          //       ? import.meta.env.VITE_API_FICHE_CONSEIL_MODEL_HOSPITALISATION
          //       : import.meta.env.VITE_API_FICHE_CONSEIL_MODEL_BLESSURE,
          //   data: dataFiche,
          // };

          // if (globalData["assurer"] !== "Moi et mes proches") {
          //   payloadEditique["deletePages"] = [1];
          // }

          // axios
          //   .post(`${import.meta.env.VITE_API_URL_EDITIQUE}`, payloadEditique, {
          //     headers: {
          //       apiKey: import.meta.env.VITE_API_KEY_EDITIQUE,
          //     },
          //   })
          //   .then((res) => {
          //     // if (res?.data?.warning === false) {
          //     let formData = {
          //       id_opp: response?.data?.id_opp,
          //       num_contrat: response?.data?.num_contrat,
          //       id_affaire: response?.data?.id_aff,
          //       members: [
          //         {
          //           firstname: globalData["nom"],
          //           lastname: globalData["prenom"],
          //           email: globalData["souscripteur_email"],
          //           phone: globalData["prefixTel"] + globalData["téléphone"],
          //           fileObjects: [
          //             {
          //               file_index: 1,
          //               page: 5,
          //               position: import.meta.env.VITE_POSITION_SIGNATURE_BA1,
          //             },
          //             {
          //               file_index: 2,
          //               page: 1,
          //               position: import.meta.env.VITE_POSITION_SIGNATURE_SEPA,
          //             },
          //             {
          //               file_index: 3,
          //               page:
          //                 globalData["assurer"] !== "Moi et mes proches"
          //                   ? 2
          //                   : 3,
          //               position: import.meta.env.VITE_POSITION_SIGNATURE_FIC,
          //             },
          //           ],
          //         },
          //       ],
          //       files: [
          //         {
          //           id_type_doc: 25,
          //           file_name: "contrat",
          //           file_index: 1,
          //           url: response?.data?.ba,
          //         },
          //         {
          //           id_type_doc: 11,
          //           file_name: "mandat sepa",
          //           file_index: 2,
          //           url: response?.data?.sepa,
          //         },
          //         {
          //           id_type_doc: 15,
          //           file_name: "fiche conseil",
          //           file_index: 3,
          //           url: res?.data?.file_url,
          //         },
          //       ],
          //     };

          //     if (
          //       (garantie.name === "MAA Prévoyance Décès" ||
          //         garantie.name === "MAA Prévoyance Pack") &&
          //       globalData["assurer"] === "Moi et mes proches"
          //     ) {
          //       formData.members = [
          //         ...formData.members,
          //         {
          //           firstname: globalData["nom_conjoint"],
          //           lastname: globalData["prenom_conjoint"],
          //           email: globalData["email_conjoint"],
          //           phone:
          //             globalData.prefixTelConjoint +
          //             globalData["telephone_conjoint"],
          //           fileObjects: [
          //             {
          //               file_index: 1,
          //               page: 5,
          //               position: import.meta.env.VITE_POSITION_SIGNATURE_BA2,
          //             },
          //           ],
          //         },
          //       ];
          //     }
          //     axios
          //       .post(
          //         `${import.meta.env.VITE_API_URL_AS}yousign/e-yousign_v2`,
          //         formData,
          //         {
          //           headers: {
          //             apiKey: partnership_id,
          //             affaireDataRef: response.data.affaire_data_ref
          //           },
          //         }
          //       )
          //       .then((res) => {
          //         // if (res.data.error === true) {
          //         //   setIsLoadingSousciption(false);
          //         //   setMOdalFail(res?.data?.message);
          //         // } else {
          //         // window.open(res.data.redirect_url, "_blank");
          //         setIsModalOpen(true);
          //         setIsLoadingSousciption(false);
          //         // }
          //       })
          //       .catch((err) => {
          //         console.log(err);
          //         setIsLoadingSousciption(false);
          //       });
          //     // } else {
          //     //   setIsLoadingSousciption(false);
          //     // }
          //   })
          //   .catch((e) => {
          //     setIsLoadingSousciption(false);
          //     // setMOdalFail(e?.response?.data?.message);
          //     console.log(e);
          //   });
          // }
        })
        .catch((e) => {
          console.log(e);
          setIsLoadingSousciption(false);
          // setMOdalFail(e?.response?.data?.message);
        });

      saveSession();
    } else {
      setIsLoadingSousciption(true);
      message.error("Veuillez ajouter votre partnership_id");
      setTimeout(() => {
        window.location = `${
          import.meta.env.VITE_API_DISTRIBUTEUR_APIURL
        }/#/sign-in`;
      }, 3000);
    }
  };
  const generateDevis = (statut) => {
    if (partnership_id) {
      setIsLoadingSousciption(true);

      var customerInfo = [
        {
          primary_subscriber: true,
          birth_info: {
            date: dayjs(globalData?.date_naissance).format("DD/MM/YYYY"),
            address: globalData?.lieuNaissance,
          },
          address: {
            street_name: `${globalData["_adresse"]}, France`,
            zip_code: globalData?._code_postal,
            city: globalData?._ville,
          },
          mail: globalData?.souscripteur_email,
          identity: {
            civility: globalData?.civilite,
            use_name: globalData?.nom,
            first_name: globalData?.prenom,
          },
          phone: [
            {
              type: "Mobile",
              country_prefix: globalData.prefixTel,
              number: (globalData?.téléphone).toString(),
            },
          ],
        },
      ];
      if (globalData?.assurer === "Moi et mes proches") {
        customerInfo = [
          ...customerInfo,
          {
            primary_subscriber: false,
            birth_info: {
              date: dayjs(globalData?.date_naissance_conjoint).format(
                "DD/MM/YYYY"
              ),
              address: globalData?.lieu_naissance_conjoint,
            },
            address: {
              street_name: `${globalData["_adresse"]}, France`,
              zip_code: globalData?._code_postal,
              city: globalData?._ville,
            },
            mail: globalData?.email_conjoint,
            identity: {
              civility: globalData?.civilteConjoint,
              use_name: globalData?.nom_conjoint,
              first_name: globalData?.prenom_conjoint,
            },
            phone: [
              {
                type: "Mobile",
                country_prefix: globalData.prefixTelConjoint,
                number: globalData?.telephone_conjoint,
              },
            ],
          },
        ];
      }

      axios
        .post(
          `${import.meta.env.VITE_API_URL_AS}tarification_maa/generate_devis`,
          {
            id_opp: idOpp,
            pack:
              garantie?.name === "MAA Prévoyance Décès"
                ? "DECES"
                : garantie?.name === "MAA Prévoyance Pack"
                ? "COMPLET"
                : garantie?.name === "MAA Prévoyance IJH"
                ? "HOSPITALISATION"
                : "BLESSURE",
            politiquement_exposee:
              garantieOptions["politiquement_exposée"] === true ? true : false,
            pricing: {
              frais_dossier: 0.0,
              customer_information: customerInfo,
              contract_information: {
                effective_date: dayjs(globalData?.dateEffet).format(
                  "DD/MM/YYYY"
                ),
              },
            },
          },
          {
            headers: {
              apiKey: partnership_id,
            },
          }
        )
        .then((response) => {
          const simpleCrypto = new SimpleCrypto(
            import.meta.env.VITE_API_ENCRYPTION_SECRET_KEY
          );
          const plainText = JSON.stringify({
            dataReprise: globalData,
            step: current,
            garantiesComplementaires: garantieOptions,
          });

          const cipherText = simpleCrypto.encrypt(plainText);
          if (statut === "exporter") {
            window.open(response?.data?.devis, "_blank");
            setIsLoadingSousciption(false);
          } else {
            axios
              .post(
                `${
                  import.meta.env.VITE_API_URL_AS
                }/session_url/set_object_from_session_url`,
                {
                  objet: {
                    data: cipherText,
                  },
                },
                {
                  headers: {
                    apiKey: partnership_id,
                  },
                }
              )
              .then((res) => {
                const reprise_id = res?.data?.id_object;
                fetch(response?.data?.devis)
                  .then((response) => response.blob())
                  .then((blob) => {
                    return new Promise((resolve, reject) => {
                      const reader = new FileReader();
                      reader.onloadend = () => resolve(reader.result);
                      reader.onerror = reject;
                      reader.readAsDataURL(blob);
                    });
                  })
                  .then((result) => {
                    const base64Data = result.split(",")[1];

                    let html = template.replace(
                      "*|FNAME|* *|LNAME|*",
                      `${globalData["prenom"]} ${globalData["nom"]}`
                    );
                    let doc_to_send = {
                      "MAA Prévoyance Décès": [
                        "https://sdn.geoprod.com/static/uploads/maa_docs/CG_MAA-4AS_1_DECES_vf-v1.pdf",
                        "https://sdn.geoprod.com/static/uploads/maa_docs/IPID_DECES_vf.pdf",
                        "https://sdn.geoprod.com/static/uploads/maa_docs/Tableau_garantie_DECES_vf.pdf",
                      ],
                      "MAA Prévoyance Blessures": [
                        "https://sdn.geoprod.com/static/uploads/maa_docs/CG_MAA-4AS_2_BLESSURES_vf-v1.pdf",
                        "https://sdn.geoprod.com/static/uploads/maa_docs/IPID_BLESSURES_vf.pdf",
                        "https://sdn.geoprod.com/static/uploads/maa_docs/Tableau_garantie_BLESSURES_vf.pdf",
                      ],
                      "MAA Prévoyance IJH": [
                        "https://sdn.geoprod.com/static/uploads/maa_docs/CG_MAA-4AS_3_HOSPI_vf-v1.pdf",
                        "https://sdn.geoprod.com/static/uploads/maa_docs/IPID_HOSPI_vf.pdf",
                        "https://sdn.geoprod.com/static/uploads/maa_docs/Tableau_garantie_HOSPI_vf.pdf",
                      ],
                      "MAA Prévoyance Pack": [
                        "https://sdn.geoprod.com/static/uploads/maa_docs/CG_MAA-4AS_4_PACK_vf-v1.pdf",
                        "https://sdn.geoprod.com/static/uploads/maa_docs/IPID_PACK_vf.pdf",
                        "https://sdn.geoprod.com/static/uploads/maa_docs/Tableau_garantie_PACK_vf.pdf",
                      ],
                    };
                    let docs_b64 = [];
                    let promises = [];

                    const documentsToFetch =
                      doc_to_send[globalData?.garantie?.name];

                    function fetchAndConvertToBase64(url) {
                      return fetch(url)
                        .then((response) => response.blob())
                        .then((blob) => {
                          return new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onloadend = () => resolve(reader.result);
                            reader.onerror = reject;
                            reader.readAsDataURL(blob);
                          });
                        });
                    }

                    for (const element of documentsToFetch) {
                      promises.push(fetchAndConvertToBase64(element));
                    }

                    Promise.all(promises)
                      .then((results) => {
                        results.forEach((result, idx) => {
                          let b64 = result.split(",")[1];
                          docs_b64.push({
                            type: "b64",
                            name: documentsToFetch[idx].split("/").pop(),
                            content: b64,
                          });
                        });

                        axios
                          .post(
                            `${
                              import.meta.env.VITE_API_URL_AS
                            }mailchimp/send_mail`,
                            {
                              recipient: globalData["souscripteur_email"],
                              sender: "devis@as-solutions.fr",
                              subject: "Devis",
                              body: "",
                              id_opp: idOpp,
                              url:
                                window.location.origin +
                                "?id=" +
                                reprise_id +
                                "&partnership_id=" +
                                partnership_id,
                              html: html.replace(
                                "[NOM OFFRE]",
                                garantie?.name.replace("MAA", "")
                              ),
                              attachments: [
                                {
                                  type: "b64",
                                  name: "Devis.pdf",
                                  content: base64Data,
                                },
                                ...docs_b64,
                              ],
                            }
                          )
                          .then((res) => {
                            setIsLoadingSousciption(false);
                            setSubscribeEnabled(false);
                            let data = {
                              ...globalData,
                              subscribeEnabled: false,
                            };
                            sessionStorage.setItem(
                              "globalData",
                              JSON.stringify(data)
                            );
                            notification.open({
                              message: "",
                              description: "E-mail envoyé avec succès ! ",
                              icon: (
                                <CheckCircleOutlined
                                  style={{
                                    color: "#62B7B2",
                                  }}
                                />
                              ),
                            });
                          })
                          .catch((err) => {
                            setIsLoadingSousciption(false);
                            console.error(
                              "Erreur lors de l'envoi de l'email : ",
                              err
                            );
                          });
                      })
                      .catch((err) => {
                        console.error(
                          "Erreur lors de la récupération et de la conversion des documents : ",
                          err
                        );
                      });
                  });
              })
              .catch((err) => {
                setIsLoadingSousciption(false);
              });
          }

          setFile(response?.data?.devis);
        })
        .catch((err) => {
          console.log(err);
          setIsLoadingSousciption(false);
        });
    } else {
      setIsLoadingSousciption(true);
      message.error("Veuillez ajouter votre partnership_id");
      setTimeout(() => {
        window.location = `${
          import.meta.env.VITE_API_DISTRIBUTEUR_APIURL
        }/#/sign-in`;
      }, 3000);
    }
  };
  const sendEmail = () => {
    generateDevis("envoyer");
  };
  const exporterDevis = () => {
    generateDevis("exporter");
  };

  if (brandProperties) {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#E7E7E7",
            lineWidth: 2,
          },
          components: {
            DatePicker: {
              colorBorder: "#E7E7E7",
              colorPrimaryHover: "#E7E7E7",
            },
            Radio: {
              colorPrimary: brandProperties?.principalColor,
            },

            Input: {
              lineWidth: 2,
              colorBorder: "#E7E7E7",
              colorPrimaryHover: "#E7E7E7",
              controlHeight: 45,
              fontSize: 16,
              colorText: "#000",
            },

            Steps: {
              colorPrimary: brandProperties?.principalColor,
              colorText: brandProperties?.principalColor,
              fontFamily: "Montserrat",
              controlItemBgActive: "rgb(223, 223, 223)",
              colorBorderSecondary: "rgb(223, 223, 223)",
              colorTextLabel: "rgb(255, 255, 255)",
              colorSplit: "rgb(223, 223, 223)",
              colorFillContent: "rgb(223, 223, 223)",
              iconFontSize: 16,
              iconSize: 38,
              margin: 20,
              // fontSize: 16,
            },
            Switch: {
              colorPrimary: "#024FA8",
              colorPrimaryHover: "#024FA8",
              colorTextQuaternary: "#A4A4A4",
              lineHeight: 2,
            },

            Select: {
              colorBorder: "#E7E7E7",
              lineWidth: 2,
              controlHeight: 45,
              colorPrimaryHover: "E7E7E7",
              fontSize: 16,
              colorText: "#000",
            },
            Checkbox: {
              colorPrimary: "#024FA8",
              colorPrimaryHover: "#024FA8",
              controlInteractiveSize: 22,
            },
            Modal: {
              colorBgMask: "rgba(2, 79, 168, 0.4)",
            },
          },
        }}
        locale={frFR}
      >
        <div>
          {isLoadingSouscription && <CustomLoader transparent="true" />}
          <Header steps={steps} current={current} setCurrent={setCurrent} />
          <ModalSuccess open={isModalOpen} form={form} />
          <ModalFail open={modalfail} hideModal={hideModal} />
          <Form
            noValidate
            form={form}
            layout="vertical"
            onFinishFailed={() => {
              message.error("Veuillez vérifier tous les champs.");
            }}
            onFinish={() => {
              next();
            }}
            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
            className={classes.formContainer}
          >
            <Steps
              className={classes.stepper}
              current={current - 1}
              onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
              items={items}
            />
            <div className={classes.contentStyle}>
              {steps[current - 1].content}
            </div>
            <Row
              className={classes.btnsHolder}
              style={{ flexDirection: current === 1 ? "column" : "row" }}
            >
              <Col>
                {current !== 1 && (
                  <button
                    className={classes.btnPrev}
                    style={{ color: brandProperties?.secondColor }}
                    type="button"
                    onClick={() => prev()}
                  >
                    Retour
                  </button>
                )}
              </Col>
              <Col>
                {current > 0 && current <= steps.length && (
                  <button
                    className={classes.btnNext}
                    style={{
                      backgroundColor:
                        (current === 4 && subscribeEnabled === true) ||
                        (current === 3 && isLoading)
                          ? "#bdbebd"
                          : brandProperties?.secondColor,
                      cursor:
                        (current === 4 && subscribeEnabled === true) ||
                        (current === 3 && isLoading)
                          ? "not-allowed"
                          : "pointer",
                      padding:
                        current === 3 || current === 4
                          ? "1rem 1rem"
                          : "1rem 2rem",
                    }}
                    disabled={
                      current === 4
                        ? subscribeEnabled
                        : current === 3 && isLoading
                        ? true
                        : false
                    }
                    type="submit"
                  >
                    {current === 1
                      ? "Suivant"
                      : current === 2
                      ? "Suivant"
                      : current === 5
                      ? "Confirmer la transaction"
                      : current === 3
                      ? "Souscrire"
                      : `Je m’assure pour ${garantie?.tarif?.toFixed(
                          2
                        )} €/mois`}
                  </button>
                )}
              </Col>
            </Row>{" "}
          </Form>
          <FloatButton
            type="primary"
            icon={<ClearOutlined />}
            tooltip={<div>Remise à zero</div>}
            onClick={() => {
              sessionStorage.clear();
              window.location.reload();
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {current === 4 && (
              <ExportDevis
                sendEmail={sendEmail}
                exporterDevis={exporterDevis}
              />
            )}
          </div>
        </div>
      </ConfigProvider>
    );
  } else {
    return <CustomLoader />;
  }
}

export default Home;

import { Col, ConfigProvider, Form, Row, Switch } from "antd";
import React, { useContext, useEffect } from "react";
import classes from "./Card.module.css";
import GlobalContext from "../../../contexts/GlobalContext";
function Card(props) {
  const {
    collg,
    colMd,
    colxs,
    text,
    name,
  
  } = props;
  const { brandProperties,garantieOptions, setGarantieOptions } = useContext(GlobalContext);

  return (
    <Col lg={collg} md={colMd} xs={colxs}>
      <div
        className={classes.containerCard}
        style={{
          border:
            garantieOptions[name] === true
              ? "1px solid #c1e5fe"
              : `1px solid ${brandProperties?.secondColor}`,
          backgroundColor:
            garantieOptions[name] === true ? "#F2FAFF" : "#FAFAFA",
        }}
      >
        <p className={classes.textCard}>{text}</p>
        <div className={classes.switchContainer}>
          <ConfigProvider
            theme={{
              components: {
                Switch: {
                  colorPrimary: "#C1E5FE",
                  colorWhite: brandProperties?.principalColor,
                  colorPrimaryHover: "#C1E5FE",
                  colorTextLightSolid: brandProperties?.principalColor,
                  colorTextQuaternary: "#FFD5D5",
                  colorTextTertiary: "#FFD5D5",
                  fontSize: 16,
                },
              },
            }}
          >
            <Form.Item name={name} valuePropName="checked">
              <Switch
                className="custom-ant-switch"
                checkedChildren="Oui"
                unCheckedChildren="Non"
                defaultChecked={garantieOptions[name]}
                onChange={(checked) => {
                  setGarantieOptions({
                    ...garantieOptions,
                    [name]: checked,
                  });
                }}
              />
            </Form.Item>
          </ConfigProvider>
        </div>
      </div>
    </Col>
  );
}

export default Card;

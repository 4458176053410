import GlobalContext from "./GlobalContext";
import { useState, useEffect } from "react";
import axios from "../utils/axios";
import { Form, message } from "antd";
import SimpleCrypto from "simple-crypto-js";
const AppContext = (props) => {
  const [brandProperties, setBrandProperties] = useState();
  const [couvertureErr, setCouverturesErr] = useState(false);
  const [entreprise, setEntreprise] = useState();
  const [form] = Form.useForm();
  const [cotisation, setCotisation] = useState(
    JSON.parse(sessionStorage.getItem("globalData"))?.type_fractionnement
      ? JSON.parse(sessionStorage.getItem("globalData"))
          ?.type_fractionnement === "Mensuel"
        ? "cotisationMensuel"
        : "cotisationAnnuel"
      : "cotisationMensuel"
  );
  const [globalData, setGlobalData] = useState(
    sessionStorage.getItem("globalData")
      ? JSON.parse(sessionStorage.getItem("globalData"))
      : ""
  );
  const [validRef, setValidRef] = useState(false);
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const partnership_id = searchParams?.get("partnership_id");
  const id = searchParams?.get("id");

  const [subscribeEnabled, setSubscribeEnabled] = useState(id ? false : true);
  const [methodePaimenet, setMethodePaiement] = useState(
    JSON.parse(sessionStorage.getItem("globalData")) &&
      JSON.parse(sessionStorage.getItem("globalData"))["methodePaiement"]
      ? JSON.parse(sessionStorage.getItem("globalData"))["methodePaiement"]
      : "Mandat SEPA"
  );
  const [assurerProches, setAssurerProches] = useState(
    JSON.parse(sessionStorage.getItem("globalData")) &&
      JSON.parse(sessionStorage.getItem("globalData"))["assurer"]
      ? JSON.parse(sessionStorage.getItem("globalData"))["assurer"]
      : "Moi seulement"
  );
  const [current, setCurrent] = useState(
    Number(sessionStorage.getItem("current")) || 1
  );

  const [garantieOptions, setGarantieOptions] = useState(
    sessionStorage.getItem("garantieOptions")
      ? JSON.parse(sessionStorage.getItem("garantieOptions"))
      : {
          conséquences_accidents_corporels: true,
          accidents_corporels_conséquences_graves: true,
          indemnité_journalière_cas_hospitalisation: true,
          versement_capital_forfaitaire: true,
          subi_accident_cinq_dernières_années: false,
          hospitalisation_programmée: false,
          mesure_curatelle_tutelle: false,
          papiers_sans_aide_ext: false,
          politiquement_exposée: false,
          conséquences_accidents_corporels_conjoint: true,
          accidents_corporels_conséquences_graves_conjoint: true,
          indemnité_journalière_cas_hospitalisation_conjoint: true,
          versement_capital_forfaitaire_conjoint: true,
          subi_accident_cinq_dernières_années_conjoint: false,
          hospitalisation_programmée_conjoint: false,
        }
  );
  const [tarifs, setTarifs] = useState([]);
  const [file, setFile] = useState();
  const [idOpp, setIdOpp] = useState("");
  const [userData, setUserData] = useState(null);

  const [garantie, setGarantie] = useState(
    JSON.parse(sessionStorage.getItem("globalData"))
      ? JSON.parse(sessionStorage.getItem("globalData"))["garantie"]
      : ""
  );
  useEffect(() => {
    if (id) {
      axios
        .get(
          `${
            import.meta.env.VITE_API_URL_AS
          }/session_url/get_object_from_session_url/${id}`,
          {
            headers:{
              apiKey : partnership_id
            }
          }
        )
        .then((res) => {
          const simpleCrypto = new SimpleCrypto(
            import.meta.env.VITE_API_ENCRYPTION_SECRET_KEY
          );
          const decryptedData = simpleCrypto?.decrypt(
            res.data?.data.replaceAll(" ", "+")
          );
          const { dataReprise, step, garantiesComplementaires } = decryptedData;
          setGlobalData(dataReprise);
          setGarantie(dataReprise["garantie"]);
          setAssurerProches(dataReprise["assurer"]);
          form.setFieldsValue(dataReprise);
          setCurrent(step);
          setGarantieOptions(garantiesComplementaires);
          sessionStorage.setItem("globalData", JSON.stringify(dataReprise));
          sessionStorage.setItem("garantieOptions", JSON.stringify(garantiesComplementaires));
          setValidRef(true);
        })
        .catch(() => {});
    }

    if (partnership_id) {
      sessionStorage.setItem("api_key", partnership_id);
      let config = {
        headers: {
          Authorization: `Bearer ${partnership_id}`,
        },
      };
      axios
        .post(`${import.meta.env.VITE_API_GEOPROD_URL}/login`, {}, config)
        .then((response) => {
          sessionStorage.setItem("token", response.data.Acces_Token);
          setUserData(response.data);
          axios
            .get(
              `${import.meta.env.VITE_API_GEOPROD_URL}/organismes/get_by_id/${
                response.data.user.entreprise_id
              }`,
              {
                headers: {
                  idSession: response?.data?.Acces_Token,
                },
              }
            )
            .then((res) => {
              setEntreprise({
                nomEntreprise: res?.data?.nom,
                orias: res?.data?.orias,
                rcs: res?.data?.rcs,
                adresseEntreprise: res?.data?.adresse,
              });
              axios
                .get(
                  `${
                    import.meta.env.VITE_API_GEOPROD_URL
                  }/organismes/get_by_id/${res?.data?.organisme_master}`,
                  {
                    headers: {
                      idSession: response?.data?.Acces_Token,
                    },
                  }
                )
                .then((res) => {
                  setBrandProperties({
                    logo: res.data.logo,
                    principalColor: "#024FA8",
                    secondColor: "#FF422C",
                  });
                });
            });
        })
        .catch((err) => console.log(err));
    } else {
      message.error("Veuillez ajouter votre partnership_id");
      setTimeout(() => {
        window.location = `${
          import.meta.env.VITE_API_DISTRIBUTEUR_APIURL
        }/#/sign-in`;
      }, 3000);
    }
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        brandProperties,
        garantie,
        setGarantie,
        couvertureErr,
        setCouverturesErr,
        globalData,
        setGlobalData,
        methodePaimenet,
        setMethodePaiement,
        tarifs,
        setTarifs,
        assurerProches,
        setAssurerProches,
        file,
        setFile,
        id,
        userData,
        idOpp,
        setIdOpp,
        entreprise,
        current,
        setCurrent,
        cotisation,
        setCotisation,
        form,
        subscribeEnabled,
        setSubscribeEnabled,
        partnership_id,
        validRef,
        setValidRef,
        garantieOptions,
        setGarantieOptions,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default AppContext;

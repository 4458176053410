import React from "react";
import styles from "./DetailItem.module.css";
import { AddCircle } from "../../../components/Icons/Icons";
import { Col, Row } from "antd";

const DetailItem = (props) => {
  const { detail } = props;
  return (
    <div className={styles.detailsItem}>
      <div className={styles.addIcon}>
        <AddCircle height={20} width={20} />
      </div>
      <div className={styles.detailsText}>{detail}</div>
    </div>
  );
};

export default DetailItem;

import { Col, Form, Row } from "antd";
import React, { useContext } from "react";
import classes from "./CardComponent.module.css";
import GlobalContext from "../../../contexts/GlobalContext";

const CardComponent = (props) => {
  const { card, form } = props;
  const { garantie, setGarantie, brandProperties, setSubscribeEnabled } =
    useContext(GlobalContext);
  return (
    <Col lg={6} md={16} xs={24} className={classes.container}>
      <Form.Item name="garantie">
        <Row
          className={classes.imageContainer}
          onClick={() => {
            let newCard = {
              name: card.name,
              tarif: card.tarif,
            };
            setGarantie(newCard);
            form.setFieldsValue({
              ...form.getFieldsValue(),
              garantie: newCard,
            });
            
            if (garantie.name !== card.name) {
              setSubscribeEnabled(true);
            }
          }}
          style={{
            border:
              garantie?.name === card?.name
                ? " solid 2px #C1E5FE"
                : "#DFDFDF solid 2px",
            backgroundColor:
              garantie?.name === card?.name ? "#F2FAFF" : "#F8F8F8",
            boxShadow:
              garantie?.name === card?.name
                ? "0px 2px 12px 0px rgba(193, 229, 254, 0.80)"
                : "none",
          }}
        >
          <div className={classes.image}> {card.cardImage}</div>
          {garantie?.name === card?.name && (
            <div className={classes.checkIcon}>
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.0005 0.00044566C10.4298 -0.00244105 7.91688 0.758227 5.7939 2.18715C3.65647 3.61462 1.99555 5.64544 1.01349 8.01976C-0.0118964 10.3825 -0.271854 13.0108 0.291387 15.5251C0.767975 18.0351 1.9667 20.3489 3.75752 22.1761C5.59166 23.9688 7.88791 25.2029 10.4008 25.7311C12.2927 26.1223 14.2425 26.0862 16.12 25.6258C17.9975 25.1668 19.7448 24.295 21.2324 23.0739C22.7344 21.8528 23.9331 20.3142 24.7563 18.5707C25.5795 16.8257 26.0128 14.9204 25.9983 12.9906C26.0272 11.2802 25.6807 9.58435 24.9875 8.01976C24.3664 6.44217 23.4421 5.00886 22.2434 3.8051C20.987 2.61434 19.5139 1.6603 17.9108 0.989139C16.3655 0.32231 14.6902 -0.0139879 13.0005 0.00044566ZM19.6438 9.3996L11.2675 17.7869C11.1808 17.8908 11.0652 17.976 10.9496 18.0351C10.8197 18.0943 10.6898 18.126 10.5454 18.1304C10.401 18.126 10.271 18.0928 10.141 18.0322C10.0254 17.9702 9.90994 17.8836 9.82329 17.7753L6.35716 14.2766C6.35716 14.2535 6.35719 14.2262 6.21277 14.1959V14.0024C6.35719 13.9721 6.35716 13.9447 6.35716 13.9216L7.51242 12.8131V12.7582C7.51242 12.7452 7.65698 12.7381 7.65698 12.7381V12.7582C7.8014 12.7698 7.80137 12.7886 7.80137 12.8131L10.5454 15.5857L18.1996 7.91149C18.1996 7.88695 18.1995 7.86819 18.344 7.8552V7.83643C18.344 7.83643 18.4885 7.83638 18.4885 7.8537V7.91722L19.6438 9.04739V9.12817C19.7882 9.15848 19.7882 9.1917 19.7882 9.2249C19.7882 9.2581 19.7882 9.29129 19.6438 9.32304V9.3996Z"
                  fill={brandProperties?.principalColor}
                />
              </svg>
            </div>
          )}
        </Row>
      </Form.Item>

      <Row className={classes.spans}>
        <span
          className={classes.nameGarantie}
          style={{ color: brandProperties?.principalColor }}
        >
          {" "}
          {card?.name}
        </span>

        <span className={classes.tarif}>
          {card.tarif?.toFixed(2)} €
          <span className={classes.mois}> par mois</span>
        </span>

        <span className={classes.description}>{card?.description}</span>
      </Row>
    </Col>
  );
};

export default CardComponent;

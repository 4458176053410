import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AppContext from "./contexts/AppContext";
import NotFound from "./components/NotFound/NotFound";
import Home from "./pages/Home/Home";
import "dayjs/locale/fr";
import dayjs from "dayjs";
import * as Sentry from "@sentry/react";
import { version } from "../package.json";
import { Footer } from "antd/es/layout/layout";
dayjs.locale("fr");

if (
  ["production", "staging"].includes(import.meta.env.VITE_API_ENVIRONEMMENT)
) {
  Sentry.init({
    dsn: "https://32675238f3c4580bad0f06152610aa14@sentry.neopolis-dev.com/48",
    tracesSampleRate: 1.0,
    environment:import.meta.env.VITE_API_ENVIRONEMMENT
  });
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <NotFound />,
  },
  {
    path: "/not-found",
    element: <NotFound />,
  },
]);
ReactDOM.createRoot(document.getElementById("root")).render(
  <AppContext>
    <RouterProvider router={router} />
    <Footer
      style={{
        textAlign: "center",
      }}
    >
      {" "}
      V {version} - {import.meta.env.VITE_API_TAG}{" "}
    </Footer>
  </AppContext>
);

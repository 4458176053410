import { Col, Row, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import {
  Blessure,
  Hospitalisation,
  Deces,
  errorIcon,
  PackGaranties,
} from "../../components/Icons/Icons";
import CardComponent from "./CardComponent/CardComponent";
import classes from "./Couvertures.module.css";
import GlobalContext from "../../contexts/GlobalContext";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import FormHeader from "../../components/FormHeader/FormHeader";
import axios from "../../utils/axios";
import dayjs from "dayjs";
const Couvertures = (props) => {
  const { form, isLoading, setIsLoading } = props;
  const {
    garantie,
    couvertureErr,
    globalData,
    tarifs,
    setGarantie,
    setTarifs,
    partnership_id,
    // loaderFail, setLoaderFail,
    
  } = useContext(GlobalContext);
  // const [isLoading, setIsLoading] = useState(true);
  const cards = [
    {
      name: "BLESSURE",
      description:
        "Une assurance pour prévenir les risques de fractures, brûlures, blessures à l’œil,et amputations à la suite d’un accident corporel",
      cardImage: <Blessure />,
    },
    {
      name: "HOSPITALISATION",
      description:
        "Une assurance pour vous couvrir des conséquences des hospitalisations consécutives à une maladie ou un accident",
      cardImage: <Hospitalisation />,
    },
    {
      name: "DECES",
      description:
        "Une assurance pour vous couvrir et couvrir vos proches en cas d’accident grave ou mortel",
      cardImage: <Deces />,
    },
    {
      name: "COMPLET",
      description:
        "En choisissant ce pack, vous bénéficierez d'une réduction exclusive.",
      cardImage: <PackGaranties />,
    },
  ];

  useEffect(() => {
    setIsLoading(true)
    if (partnership_id) {
      var customerInfoTarif = [
        {
          date_naissance: dayjs(globalData["date_naissance"]).format("DD/MM/YYYY"),
          assure: "PRINCIPALE",
        },
      ];
      if (globalData?.assurer === "Moi et mes proches") {
        customerInfoTarif = [
          ...customerInfoTarif,
          {
            date_naissance: dayjs(globalData["date_naissance_conjoint"]).format("DD/MM/YYYY"), 
            assure: "CONJOINT",
          },
        ];
      }
      const data = {
        customers_informations: customerInfoTarif,
        effective_date: dayjs(globalData?.dateEffet).format("DD/MM/YYYY"),
      };
      let config = {
        headers: {
          apiKey: partnership_id,
        },
      };
      axios
        .post(
          `${import.meta.env.VITE_API_URL_AS}/tarification_maa/get_tarif`,
          data,
          config
        )
        .then((res) => {
            setIsLoading(false);
            // setLoaderFail(false);
            let newTarifs = [{}, {}, {}, {}];
  
            Object?.keys(res?.data?.message?.TOTAL.PACKS)?.map((pack) => {
              const card = cards.find((card) => card.name === pack);
              newTarifs.splice(
                pack === "HOSPITALISATION"
                  ? 1
                  : pack === "BLESSURE"
                  ? 2
                  : pack === "DECES"
                  ? 3
                  : 4,
                0,
                {
                  name:
                    pack === "HOSPITALISATION"
                      ? "MAA Prévoyance IJH"
                      : pack === "BLESSURE"
                      ? "MAA Prévoyance Blessures"
                      : pack === "DECES"
                      ? "MAA Prévoyance Décès"
                      : "MAA Prévoyance Pack",
                  tarif: res?.data?.message?.TOTAL.PACKS[pack].tarif_commercial,
                  description: card.description,
                  cardImage: card.cardImage,
                }
              );
  
              setTarifs(newTarifs.filter((item) => Object.keys(item).length > 0));
            });
            if (garantie && garantie?.length !== 0) {
              let newCard = {
                name: garantie?.name,
                tarif:
                  res?.data?.message?.TOTAL?.PACKS[
                    garantie?.name === "MAA Prévoyance Décès"
                      ? "DECES"
                      : garantie?.name === "MAA Prévoyance Pack"
                      ? "COMPLET"
                      : garantie?.name === "MAA Prévoyance IJH"
                      ? "HOSPITALISATION"
                      : "BLESSURE"
                  ]?.tarif_commercial,
              };
              setGarantie(newCard);
            }
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(true);
          setGarantie("");
          // setLoaderFail(true);
          // setMOdalFail(e?.response?.data?.message);
        });
    }else{
      setIsLoading(true);
      message.error("Veuillez ajouter votre partnership_id");
      setTimeout(() => {
        window.location = `${
          import.meta.env.VITE_API_DISTRIBUTEUR_APIURL
        }/#/sign-in`;
      }, 3000);
    }
  
  }, []);
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <div >
      {!isLoading ? (
        <Col className={classes.container}>
          {couvertureErr && (
            <Row className={classes.rowErreur}>
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.3869 18.5733L17.9202 5.13334C16.9169 3.32501 15.5286 2.33334 14.0002 2.33334C12.4719 2.33334 11.0836 3.32501 10.0802 5.13334L2.61355 18.5733C1.66855 20.2883 1.56355 21.9333 2.32188 23.2283C3.08022 24.5233 4.57355 25.235 6.53355 25.235H21.4669C23.4269 25.235 24.9202 24.5233 25.6786 23.2283C26.4369 21.9333 26.3319 20.2767 25.3869 18.5733ZM13.1252 10.5C13.1252 10.0217 13.5219 9.62501 14.0002 9.62501C14.4786 9.62501 14.8752 10.0217 14.8752 10.5V16.3333C14.8752 16.8117 14.4786 17.2083 14.0002 17.2083C13.5219 17.2083 13.1252 16.8117 13.1252 16.3333V10.5ZM14.8286 20.6617C14.7702 20.7083 14.7119 20.755 14.6536 20.8017C14.5836 20.8483 14.5136 20.8833 14.4436 20.9067C14.3736 20.9417 14.3035 20.965 14.2219 20.9767C14.1519 20.9883 14.0702 21 14.0002 21C13.9302 21 13.8486 20.9883 13.7669 20.9767C13.6969 20.965 13.6269 20.9417 13.5569 20.9067C13.4869 20.8833 13.4169 20.8483 13.3469 20.8017C13.2885 20.755 13.2302 20.7083 13.1719 20.6617C12.9619 20.44 12.8336 20.1367 12.8336 19.8333C12.8336 19.53 12.9619 19.2267 13.1719 19.005C13.2302 18.9583 13.2885 18.9117 13.3469 18.865C13.4169 18.8183 13.4869 18.7833 13.5569 18.76C13.6269 18.725 13.6969 18.7017 13.7669 18.69C13.9186 18.655 14.0819 18.655 14.2219 18.69C14.3035 18.7017 14.3736 18.725 14.4436 18.76C14.5136 18.7833 14.5836 18.8183 14.6536 18.865C14.7119 18.9117 14.7702 18.9583 14.8286 19.005C15.0386 19.2267 15.1669 19.53 15.1669 19.8333C15.1669 20.1367 15.0386 20.44 14.8286 20.6617Z"
                  fill="#FF422C"
                />
              </svg>
              <span className={classes.erreur}>
                Vous devez choisir une garantie.
              </span>
            </Row>
          )}
          <FormHeader name="Les garanties souhaitées pour votre assurance" />
          <Row gutter={[16]} className={classes.card}>
            {tarifs?.map((card, index) => (
              <CardComponent card={card} key={index} form={form} />
            ))}
          </Row>
        </Col>
      ) 
      // : loaderFail ? (
      //  "" 
      // ) 
      : (
        <CustomLoader text="Nous calculons votre prime..." />
      )}
    </div>
  );
};

export default Couvertures;

import React, { useContext, useEffect, useState } from "react";
import InputComponent from "../../components/InputComponent/InputComponent";
import classes from "./InformationsSouscripteur.module.css";
import { PhoneOutlined } from "@ant-design/icons";
import { Col, Radio, Row } from "antd";
import { Form } from "antd";
import GlobalContext from "../../contexts/GlobalContext";
import axios from "../../utils/axios";
import FormHeader from "../../components/FormHeader/FormHeader";
import dayjs from "dayjs";
const InformationsSouscripteur = (props) => {
  const { form,villes, setVilles } = props;
  const { brandProperties, assurerProches, setAssurerProches, globalData } =
    useContext(GlobalContext);
  // const [villes, setVilles] = useState([]);
  const radioValues = ["Moi seulement", "Moi et mes proches"];
  const dateEffetMaxDate = dayjs().add(31, "day");


  const getVilles = (e) => {
    form.setFieldsValue({
          _code_postal: e.target.value.replace(/[^0-9]/g, ""),
        });
        form.validateFields(["_code_postal"]); 
    if (e.target.value.length > 4) {
      const URL = import.meta.env.VITE_API_URL_AS;
      let ville_names = [];

      axios
        .post(`${URL}/groupe_villes/get_villes_by_code_postal`, {
          postal_code: e.target.value,
        })
        .then((res) => {{}
          if (res.data == "list index out of range") {
            setVilles([]);

            form.setFieldsValue({
              ...form.getFieldsValue(),
              _ville: "",
            });
          } else {
            res.data.villes.forEach((ville) => {
              let new_ville = {
                value: ville?.nom_comm,
                label: ville?.nom_comm,
              };
              if (!ville_names.find((val) => val.label == ville?.nom_comm)) {
                ville_names.push(new_ville);
              }
            });
            setVilles(ville_names);
            form.setFieldsValue({
              ...form.getFieldsValue(),
              _ville: ville_names[0]?.value,
            });
          }
        })
        .catch((err) => {
          setVilles([]);
          form.setFieldsValue({
            ...form.getFieldsValue(),
            _ville: "",
          });
        });
    } else {
      setVilles([]);
      form.setFieldsValue({
        ...form.getFieldsValue(),
        _ville: "",
      });
    }
  };
  useEffect(() => 
    window.scrollTo(0, 0), 
  []);
  return (
    <div>
      <FormHeader name="Informations du souscripteur" />

      <Row gutter={24}>
        <InputComponent
          name="civilite"
          messageRemplissage="Veuillez remplir ce champ."
          label="Civilité"
          inputType="select"
          required="true"
          options={[
            {
              value: "Mme",
              label: "Mme.",
            },
            {
              value: "Mr",
              label: "M.",
            },
          ]}
          collg={12}
          colMd={12}
          colxs={24}
          className={classes.label}
        />
        <InputComponent
          name="nom"
          required={true}
          messageRemplissage="Veuillez remplir ce champ."
          pattern={
            new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[’'-][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/)
          }
          messageVerification="Veuillez vérifier ce champ."
          label="Nom"
          size="large"
          inputType="input"
          className={classes.label}
          collg={12}
          colMd={12}
          colxs={24}
        />
        <InputComponent
          name="prenom"
          required={true}
          messageRemplissage="Veuillez remplir ce champ."
          pattern={
            new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[’'-][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/)
          }
          messageVerification="Veuillez vérifier ce champ."
          label="Prénom"
          size="large"
          className={classes.label}
          inputType="input"
          collg={12}
          colMd={12}
          colxs={24}
        />

        <InputComponent
          name="_adresse"
          required={true}
          messageRemplissage="Veuillez remplir ce champ."
          label="Adresse"
          size="large"
          className={classes.label}
          inputType="input"
          collg={12}
          colMd={12}
          colxs={24}
        />

        <InputComponent
          name="_code_postal"
          required={true}
          messageRemplissage="Veuillez remplir ce champ."
          label="Code Postal"
          size="large"
          messageVerification="Veuillez vérifier ce champ."
          pattern={new RegExp(/^.{5,}$/)}
          className={classes.label}
          inputType="input"
          collg={12}
          colMd={12}
          colxs={24}
          // onchange={getVilles}
          maxLength={5}
          // onchange={(e) => {
          //   form.setFieldsValue({
          //     _code_postal: e.target.value.replace(/[^0-9]/g, ""),
          //   });
          //   form.validateFields(["_code_postal"]);
          // }}
          onchange={(e) => {
            getVilles(e);
          }}
        />

        <InputComponent
          name="_ville"
          required={true}
          messageRemplissage="Veuillez remplir ce champ."
          label="Ville"
          size="large"
          className={classes.label}
          inputType="select"
          collg={12}
          colMd={12}
          colxs={24}
          options={villes}
        />

        <InputComponent
          name="pays"
          required={false}
          messageRemplissage="Veuillez remplir ce champ."
          label="Pays"
          size="large"
          className={classes.label}
          inputType="input"
          collg={24}
          colMd={24}
          colxs={24}
          placeholder="France"
          defaultValue="France"
        />
        <InputComponent
          name="lieuNaissance"
          required={true}
          messageRemplissage="Veuillez remplir ce champ."
          label="Lieu de naissance"
          size="large"
          className={classes.label}
          inputType="input"
          collg={24}
          colMd={24}
          colxs={24}
        />
        <InputComponent
          name="date_naissance"
          required={true}
          messageRemplissage="Veuillez remplir ce champ."
          label="Date de naissance"
          size="large"
          className={classes.label}
          messageVerificationDate={
            "Veuillez noter que l'âge de souscription pour cette assurance doit être compris entre 18 et 65 ans à compter de la date d'effet de la police."
          }
          inputType="date"
          minDate={18}
          maxDate={65}
          form={form}
          collg={24}
          colMd={24}
          colxs={24}
        />

        <InputComponent
          name="souscripteur_email"
          required={true}
          messageRemplissage="Veuillez remplir ce champ."
          pattern={new RegExp(/^[\w-\.]+@([\w-]+\.)+[a-zA-Z]{2,4}$/)}
          label="Adresse e-mail "
          size="large"
          className={classes.label}
          messageVerification="Veuillez vérifier ce champ."
          inputType="input"
          collg={12}
          colMd={12}
          colxs={24}
        />

        {/* <InputComponent
          addonAfter={<PhoneOutlined style={{ color: "black" }} />}
          name="téléphone"
          required={true}
          messageRemplissage="Veuillez remplir ce champ."
          label="Numéro de téléphone"
          size="large"
          // pattern={new RegExp(/^(?!$|012345678$|123456789)(?!\d{10,})\d+$/)}
          pattern={new RegExp(/^0?[67]\d{8}$/)}
          messageVerification="Veuillez vérifier ce champ."
          className={classes.label}
          type="Number"
          inputType="input"
          prefix={"+33"}
          collg={12}
          colMd={12}
          colxs={24}
        /> */}
        <InputComponent
          form={form}
          name="téléphone"
          namePrefix="prefixTel"
          required={true}
          pattern={new RegExp(/^0?[1234567]\d{8}$/)}
          messageRemplissage="Veuillez remplir ce champ."
          messageVerification="Veuillez vérifier ce champ."
          label="Numéro de téléphone"
          size="large"
          className={classes.label}
          addonAfter={<PhoneOutlined style={{ color: "black" }} />}
          inputType="phone"
          collg={12}
          colMd={12}
          colxs={24}
          defaultValue={"+33"}
          handlePrefixChange={(e) => {
            form.validateFields(["téléphone"]);
            form.setFieldsValue({
              prefixTel: e.target.value.replace(/[^+0-9]/g, ""),
            });
            form.validateFields(["prefixTel"]);
          }}
          onchange={(e) => {
            form.setFieldsValue({
              téléphone: e.target.value.replace(/[^0-9]/g, ""),
            });
            form.validateFields(["téléphone"]);
          }}
        />
      </Row>

      <FormHeader name="Qui souhaitez-vous assurer ?" />
      <Form.Item name="assurer" style={{ width: "100%" }}>
        <Radio.Group style={{ width: "100%" }}>
          <Row gutter={48} justify="space-around">
            {radioValues?.map((value, index) => (
              <Col
                style={{
                  backgroundColor:
                    assurerProches === value ? "#C1E5FE" : "white",
                  border:
                    assurerProches === value
                      ? `2px solid ${brandProperties?.principalColor}`
                      : "2px solid #E7E7E7",
                }}
                key={index}
                lg={11}
                xs={21}
                sm={10}
                className={classes.radio}
                onClick={() => {
                  setAssurerProches(value);
                  form.setFieldsValue({
                    ...form.getFieldsValue(),
                    assurer: value,
                  });
                }}
              >
                <Radio value={value}>{value}</Radio>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      </Form.Item>
      {assurerProches === "Moi et mes proches" ? (
        <Row gutter={24}>
          <InputComponent
            name="civilteConjoint"
            label="Civilité conjoint"
            messageRemplissage="Veuillez remplir ce champ."
            required="true"
            inputType="select"
            options={[
              {
                value: "Mme",
                label: "Mme.",
              },
              {
                value: "Mr",
                label: "M.",
              },
            ]}
            collg={12}
            colMd={12}
            colxs={24}
            className={classes.label}
          />
          <InputComponent
            name="nom_conjoint"
            required={true}
            messageRemplissage="Veuillez remplir ce champ."
            pattern={
              new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[’'-][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/)
            }
            messageVerification="Veuillez vérifier ce champ."
            label="Nom conjoint"
            size="large"
            inputType="input"
            className={classes.label}
            collg={12}
            colMd={12}
            colxs={24}
          />
          <InputComponent
            name="prenom_conjoint"
            required={true}
            messageRemplissage="Veuillez remplir ce champ."
            pattern={
              new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[’'-][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/)
            }
            messageVerification="Veuillez vérifier ce champ."
            label="Prénom conjoint"
            size="large"
            className={classes.label}
            inputType="input"
            collg={12}
            colMd={12}
            colxs={24}
          />
          <InputComponent
            name="lieu_naissance_conjoint"
            required={true}
            messageRemplissage="Veuillez remplir ce champ."
            label="Lieu de naissance conjoint"
            size="large"
            className={classes.label}
            inputType="input"
            type="input"
            collg={12}
            colMd={12}
            colxs={24}
          />
          <InputComponent
            name="date_naissance_conjoint"
            required={true}
            messageRemplissage="Veuillez remplir ce champ."
            label="Date de naissance conjoint"
            size="large"
            className={classes.label}
            inputType="date"
            messageVerificationDate={
              "Veuillez noter que l'âge de souscription pour cette assurance doit être compris entre 18 et 65 ans à compter de la date d'effet de la police."
            }
            minDate={18}
            maxDate={65}
            form={form}
            collg={24}
            colMd={24}
            colxs={24}
          />
          <InputComponent
            name="email_conjoint"
            required={true}
            pattern={new RegExp(/^[\w-\.]+@([\w-]+\.)+[a-zA-Z]{2,4}$/)}
            messageRemplissage="Veuillez remplir ce champ."
            messageVerification="Veuillez vérifier ce champ."
            label="Adresse e-mail conjoint"
            size="large"
            className={classes.label}
            inputType="input"
            collg={12}
            colMd={12}
            colxs={24}
          />
          {/* <InputComponent
            addonAfter={<PhoneOutlined style={{ color: "black" }} />}
            name="telephone_conjoint"
            required={true}
            messageRemplissage="Veuillez remplir ce champ."
            messageVerification="Veuillez vérifier ce champ."
            type="number"
            prefix={"+33"}
            label="Numéro de téléphone conjoint"
            size="large"
            pattern={new RegExp(/^0?[67]\d{8}$/)}
            className={classes.label}
            inputType="input"
            collg={12}
            colMd={12}
            colxs={24}
          /> */}
          <InputComponent
            form={form}
            name="telephone_conjoint"
            namePrefix="prefixTelConjoint"
            required={true}
            pattern={new RegExp(/^0?[1234567]\d{8}$/)}
            messageRemplissage="Veuillez remplir ce champ."
            messageVerification="Veuillez vérifier ce champ."
            label="Numéro de téléphone conjoint"
            size="large"
            className={classes.label}
            addonAfter={<PhoneOutlined style={{ color: "black" }} />}
            inputType="phone"
            collg={12}
            colMd={12}
            colxs={24}
            defaultValue={"+33"}
            handlePrefixChange={(e) => {
              form.validateFields(["telephone_conjoint"]);
              form.setFieldsValue({
                prefixTelConjoint: e.target.value.replace(/[^+0-9]/g, ""),
              });
              form.validateFields(["prefixTelConjoint"]);
            }}
            onchange={(e) => {
              form.setFieldsValue({
                telephone_conjoint: e.target.value.replace(/[^0-9]/g, ""),
              });
              form.validateFields(["telephone_conjoint"]);
            }}
          />
        </Row>
      ) : (
        ""
      )}
      <FormHeader name="Date d’effet souhaitée du contrat  ?" />
      <Row
        style={{ display: "flex", justifyContent: "center" }}
        className="starClass"
      >
        <InputComponent
          required={true}
          inputType="date"
          type="date"
          name="dateEffet"
          messageRemplissage="Veuillez remplir ce champ."
          collg={12}
          colMd={12}
          form={form}
          colxs={24}
          maxDate={dateEffetMaxDate}
          minDate={dayjs().add(1, "day")}
        />
      </Row>
    </div>
  );
};

export default InformationsSouscripteur;

import { Col, Row } from 'antd';
import React from 'react';
import styles from './ExportDevis.module.css';
import { ExportIcon, SendIcon } from '../../../components/Icons/Icons';

const ExportDevis = (props) => {
  const { exporterDevis, sendEmail } = props;
  return (
    <Row gutter={24} className={styles.exportDevis}>
      <Col xs={24} sm={24} md={24} lg={12} className={styles.btn}>
        <button
          type='button'
          className={styles.exportButton}
          onClick={exporterDevis}
        >
          <div className={styles.exportIcon}>
            <ExportIcon />
          </div>
          <span>Exporter Devis​</span>
        </button>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} className={styles.btn}>
        <button className={styles.sendButton} onClick={sendEmail}>
          <div className={styles.exportIcon}>
            <SendIcon />
          </div>
          <span>Envoyer Devis par E-mail</span>
        </button>
      </Col>
    </Row>
  );
};

export default ExportDevis;

import React, { useState, useContext } from "react";
import styles from "./CollapsibleCard.module.css";
import { ChevronDown, ChevronUp } from "../../../components/Icons/Icons";
import { Col, Form, Row } from "antd";
import DetailItem from "../GuaranteeDetails/DetailItem";
import WarningCard from "../../../components/WarningCard/WarningCard";
import GlobalContext from "../../../contexts/GlobalContext";
import BeneficiaryInfo from "../BeneficiaryInfo/BeneficiaryInfo";
import axios from "../../../utils/axios";

const CollapsibleCard = (props) => {
  const {
    typeGarantie,
    cardIcon,
    details,
    firstWarningTitle,
    firstWarningContent,
    secondWarningTitle,
    secondWarningContent,
    additionalInfo,
    garantie,
    beneficiary,
    form,
  } = props;
  const { brandProperties } = useContext(GlobalContext);
  const onePackSelected =
    garantie?.name === "MAA Prévoyance Pack" ? false : true;
  const [isCollapsed, setIsCollapsed] = useState(onePackSelected);
  const warningCardWidth =
    firstWarningContent && secondWarningContent
      ? { xs: 18, sm: 18, md: 18, lg: 18, xl: 11 }
      : { xs: 18, sm: 18, md: 20, lg: 20, xl: 20 };
  const [villes, setVilles] = useState([]);
  const pays = [{ label: "France", value: "France" }];
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  };
  const getVilles = (code_postal) => {
    if (code_postal.length > 4) {
      const URL = import.meta.env.VITE_API_URL_AS;
      let ville_names = [];

      axios
        .post(`${URL}/groupe_villes/get_villes_by_code_postal`, {
          postal_code: code_postal,
        })
        .then((res) => {
          if (res.data == "list index out of range") {
            setVilles([]);

            form.setFieldsValue({
              ...form.getFieldsValue(),
              _ville: "",
            });
          } else {
            res.data.villes.forEach((ville) => {
              let new_ville = {
                value: ville?.nom_comm,
                label: ville?.nom_comm,
              };
              if (!ville_names.find((val) => val.label == ville?.nom_comm)) {
                ville_names.push(new_ville);
              }
            });
            setVilles(ville_names);
            form.setFieldsValue({
              ...form.getFieldsValue(),
              _ville: ville_names[0]?.value,
            });
          }
        })
        .catch((err) => console.error(err));
    }
  };
  return (
    <div className={`${styles.card} ${isCollapsed ? styles.collapsed : ""}`}>
      <div className={styles.cardContainer}>
        <div className={styles.cardContent}>
          <div
            className={styles.guaranteeName}
            style={{ color: brandProperties?.principalColor }}
          >
            {typeGarantie}
          </div>
          <div className={styles.collapseIcon} onClick={toggleCollapse}>
            {onePackSelected === true ? null : isCollapsed ? (
              <ChevronUp />
            ) : (
              <ChevronDown />
            )}
          </div>
          <div className={styles.blessureIcon}>{cardIcon}</div>
        </div>
        {isCollapsed && (
          <div className={styles.guaranteeDetails}>
            <div
              className={styles.detailsTitle}
              style={{ color: brandProperties?.principalColor }}
            >
              Détails Garantie
            </div>
            <div className={styles.detailsContainer}>
              {details.map((detail, index) => (
                <DetailItem detail={detail.title} key={index} />
              ))}
            </div>
            <Row justify={"center"}>
              {firstWarningContent ? (
                <WarningCard
                  warningTitle={firstWarningTitle}
                  warningContent={firstWarningContent}
                  warningCardWidth={warningCardWidth}
                />
              ) : null}
              {secondWarningContent ? (
                <WarningCard
                  warningTitle={secondWarningTitle}
                  warningContent={secondWarningContent}
                  warningCardWidth={warningCardWidth}
                />
              ) : null}
            </Row>
            {/* {additionalInfo ? (
              <div className={styles.centerDiv}>
                <div className={styles.additionalInfo}>{additionalInfo}</div>
              </div>
            ) : null}
            {beneficiary ? (
              <div className={styles.benificiaryInformation}>
                <div
                  className={styles.formTitle}
                  style={{ color: brandProperties.principalColor }}
                >
                  Information bénéficaire
                </div>
                <BeneficiaryInfo
                  getVilles={getVilles}
                  villes={villes}
                  pays={pays}
                />
              </div>
            ) : null} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default CollapsibleCard;

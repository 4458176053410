import { Col, Form, Radio, Row, Switch } from "antd";
import React, { useContext, useEffect, useState } from "react";
import classes from "./Paiement.module.css";
import {
  Prelevement,
  Remboursement,
  MontantPaiement,
  DelaiContrat,
} from "../../components/Icons/Icons";
import InputComponent from "../../components/InputComponent/InputComponent";
import Card from "./Cards/Card";
import FormHeader from "../../components/FormHeader/FormHeader";
import GlobalContext from "../../contexts/GlobalContext";
import dayjs from "dayjs";
const Paiement = (props) => {
  const { form } = props;
  const radioValues = ["Mandat SEPA", "Carte Bancaire"];
  const {
    brandProperties,
    methodePaimenet,
    setMethodePaiement,
    garantie,
    cotisation, setCotisation,
    globalData,
  } = useContext(GlobalContext);

  const forms = [
    "Nom de la banque",
    "Nom & Prénom titulaire du compte",
    "BIC",
    "IBAN",
  ];
  const [nBanque, setNBanque] = useState('');
  const [nPCompte, setNPCompte] = useState('');
  const [bicRemb, setBicRemb] = useState('');
  const [ibanRemb, setIbanRemb] = useState('');
  const InputsPattern = (input) => {
    switch (input) {
      case "IBAN":
        return /^[A-Z\s]{2}[0-9\s]{2}[A-Z0-9\s]{1,27}$/;
      case "BIC":
        // return /^[A-Z\s]{6}[A-Z0-9\s]{2}([A-Z0-9\s]{3})?/;
           return /^[A-Z\s]{6}[A-Z0-9\s]{2}([A-Z0-9\s]{3})?$/
      default:
        return /^(?!\s)(?!.*\s$).+/;
    }
  };
  const cards = [
    {
      image: <MontantPaiement />,
      label: `Montant en €/${
        cotisation === "cotisationMensuel" ? "mois" : "an"
      }`,
      value: `${
        cotisation === "cotisationMensuel"
          ? garantie?.tarif?.toFixed(2)
          : (garantie?.tarif * 12)?.toFixed(2)
      }`,
    },
    // {
    //   image: <FraisDossier />,
    //   label: "Frais de dossier",
    //   value:0,
    // },
    {
      image: <DelaiContrat />,
      label: "Délai du contrat",
      value: `${dayjs(globalData["dateEffet"]).format("DD/MM/YYYY")} au ${dayjs(
        globalData["dateEffet"]
      )
        .add(1, "year")
        .subtract(1, "day")
        .format("DD/MM/YYYY")}`,
    },
  ];
  const onChange = (e) => {
    if (e === true) {
      setCotisation("cotisationAnnuel");
      form.setFieldsValue({
        ...form.getFieldsValue(),
        "type_fractionnement": "Annuel",
      });
    } else {
      setCotisation("cotisationMensuel");
      form.setFieldsValue({
        ...form.getFieldsValue(),
        "type_fractionnement": "Mensuel",
      });
    }
  };
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <Col className={classes.container}>
      <FormHeader name="Confirmation de paiement" />

      <Row className={classes.rowTarif}>
        <Col>
          <span
            className={classes.tarif}
            style={{ color: brandProperties?.principalColor }}
          >
            {cotisation === "cotisationMensuel"
              ? garantie?.tarif?.toFixed(2)
              : (garantie?.tarif * 12)?.toFixed(2)}{" "}
            €
          </span>
          <Row>
            <span
              className={classes.cotisation}
              style={{
                color:
                  cotisation === "cotisationMensuel"
                    ? brandProperties?.principalColor
                    : "#A4A4A4",
              }}
            >
              {" "}
              Cotisation Mensuelle
            </span>
            <Switch
              onChange={onChange}
              checked={cotisation === "cotisationMensuel" ? false : true}
            />
            <span
              className={classes.cotisation}
              style={{
                color:
                  cotisation === "cotisationAnnuel"
                    ? brandProperties?.principalColor
                    : "#A4A4A4",
              }}
            >
              {" "}
              Cotisation Annuelle
            </span>
          </Row>
        </Col>
      </Row>

      <Row gutter={[16]} className={classes.cardsRow}>
        {cards?.map((card, index) => (
          <Card card={card} key={index} />
        ))}

      </Row>

      <FormHeader name="Méthode de paiement" />


      <Form.Item
        name="methodePaiement"
        rules={[
          {
            required: true,
            message: "veuillez choisir la méthode de paiment",
          },
        ]}
        style={{ width: "100%" }}
      >
        <Radio.Group className={classes.rowRadio}>
          <Row gutter={48} justify="space-around">
            {radioValues?.map((value, index) => {
              return (
                <Col
                  style={{
                    backgroundColor:
                      methodePaimenet === value ? "#C1E5FE" : "white",
                    border:
                      methodePaimenet === value
                        ? `${brandProperties?.principalColor} 2px solid`
                        : "2px solid #E7E7E7",
                        cursor:value!=="Carte Bancaire"?"pointer":"not-allowed"
                  }}
                  key={index}
                  xs={24}
                  sm={24}
                  lg={10}
                  md={24}
                  className={classes.radio}
                  
                  onClick={() => {
                    if(value!=="Carte Bancaire"){
                       setMethodePaiement(value);
                    form.setFieldsValue({
                      ...form.getFieldsValue(),
                      methodePaiement: value,
                    });
                    }
                   
                  }}
                >
                  <Radio value={value} disabled={value==="Carte Bancaire"}>{value}</Radio>
                </Col>
              );
            })}
          </Row>
        </Radio.Group>
      </Form.Item>

      {
        methodePaimenet === "Mandat SEPA" ? (
          <Row gutter={48} className={classes.paiementForm}>
            <InputComponent
              name="type_fractionnement"
              label="Type de fractionnement"
              required={true}
              inputType="select"
              options={[
                {
                  value: "Mensuel",
                  label: "Mensuel",
                },
                {
                  value: "Annuel",
                  label: "Annuel",
                },
              ]}
              colxs={24}
              collg={12}
              messageRemplissage="Veuillez remplir ce champ."
              className={classes.label}
              onSelect={(e) => {
                setCotisation(`cotisation${e}`);
              }}
            />

            <InputComponent
              name="Jour de prélèvement"
              label="Jour de prélèvement"
              required={true}
              inputType="select"
              options={[
                {
                  value: "5",
                  label: "5",
                },
                // {
                //   value: "10",
                //   label: "10",
                // },
                // {
                //   value: "15",
                //   label: "15",
                // },
              ]}
              colxs={24}
              className={classes.label}
              collg={12}
              messageRemplissage="Veuillez remplir ce champ."
            />

            <Col sm={24}>
              <div
                className={classes.prelevement}
                style={{
                  borderLeft: `${brandProperties?.principalColor} 2px solid`,
                }}
              >
                <Prelevement />
                <span
                  className={classes.prelevementText}
                  style={{ color: brandProperties?.principalColor }}
                >
                  Prélèvement
                </span>
              </div>
            </Col>

            {forms.map((form, index) => (
              <InputComponent
                key={index}
                name={form + "prelev"}
                label={form}
                colxs={24}
                collg={12}
                className={classes.label}
                inputType="input"
                pattern={new RegExp(InputsPattern(form))}
                required={true}
                messageRemplissage="Veuillez remplir ce champ."
                messageVerification="Veuillez vérfier ce champ."
              />
            ))}
            <Col sm={24}>
              <div
                className={classes.prelevement}
                style={{
                  borderLeft: `${brandProperties?.principalColor} 2px solid`,
                }}
              >
                <Remboursement />
                <span
                  className={classes.prelevementText}
                  style={{ color: brandProperties?.principalColor }}
                >
                  Remboursement
                </span>
              </div>
            </Col>
            <InputComponent
                name={"Nom de la banqueremb"}
                label={"Nom de la banque"}
                colxs={24}
                collg={12}
                inputType="input"
                required={nBanque.length > 0 || nPCompte.length > 0 || bicRemb.length > 0 || ibanRemb.length > 0}
                className={classes.label}
                pattern={new RegExp(/^(?!\s)(?!.*\s$).+/)}
                messageRemplissage="Veuillez remplir ce champ."
                messageVerification="Veuillez vérfier ce ce champ."
                onchange={(e) => {
                  setNBanque(e.target.value);
                }
                }

              />
                            <InputComponent
                name={"Nom & Prénom titulaire du compteremb"}
                label={"Nom & Prénom titulaire du compte"}
                colxs={24}
                collg={12}
                inputType="input"
                required={nBanque.length > 0 || nPCompte.length > 0 || bicRemb.length > 0 || ibanRemb.length > 0}
                className={classes.label}
                pattern={new RegExp(/^(?!\s)(?!.*\s$).+/)}
                messageRemplissage="Veuillez remplir ce champ."
                messageVerification="Veuillez vérfier ce ce champ."
                onchange={(e) => {
                  setNPCompte(e.target.value);
                }
                }
              />
                            <InputComponent
                name={"BICremb"}
                label={"BIC"}
                colxs={24}
                collg={12}
                inputType="input"
                required={nBanque.length > 0 || nPCompte.length > 0 || bicRemb.length > 0 || ibanRemb.length > 0}
                className={classes.label}
                pattern={new RegExp(/^[A-Z\s]{6}[A-Z0-9\s]{2}([A-Z0-9\s]{3})?/)}
                messageRemplissage="Veuillez remplir ce champ."
                messageVerification="Veuillez vérfier ce ce champ."
                onchange={(e) => {
                  setBicRemb(e.target.value);
                }
                }
              />
                <InputComponent
                name={"IBANremb"}
                label={"IBAN"}
                colxs={24}
                collg={12}
                inputType="input"
                required={nBanque.length > 0 || nPCompte.length > 0 || bicRemb.length > 0 || ibanRemb.length > 0}
                className={classes.label}
                pattern={new RegExp(/^[A-Z\s]{2}[0-9\s]{2}[A-Z0-9\s]{1,27}$/)}
                messageRemplissage="Veuillez remplir ce champ."
                messageVerification="Veuillez vérfier ce ce champ."
                onchange={(e) => {
                  setIbanRemb(e.target.value);
                }
                }
              />
          </Row>
        ) : (
          ""
        )
        //carte bancaire form
        // (
        //   <Row gutter={48} className={classes.paiementForm}>
        //     <InputComponent
        //       inputType="input"
        //       colxs={24}
        //       collg={12}
        //       name="NumCarte"
        //       label="Numéro de la carte"
        //     />
        //     <InputComponent
        //       inputType="input"
        //       colxs={24}
        //       collg={12}
        //       name="numCvc"
        //       label="Numéro CVC"
        //     />
        //     <InputComponent
        //       inputType="input"
        //       colxs={24}
        //       collg={24}
        //       name="dateExp"
        //       label="Date d’expiration"
        //     />

        //     <Col className={classes.stripe} colxs={24} collg={24}>
        //       <Row style={{ alignItems: "center" }}>
        //         <img src={stripe} style={{borderRadius:"0.3rem"}} />
        //         <span
        //           className={classes.transactionSec}
        //           style={{ color: brandProperties?.principalColor }}
        //         >
        //           Transaction sécurisée par STRIPE
        //         </span>
        //       </Row>
        //     </Col>

        //     <InputComponent
        //       inputType="checkbox"
        //       checkboxValues={[
        //         "En cochant cette case vous autorisez 'MAA'  à envoyer des instructions à votre banque pour débiter votre compte du montant des échéances de vos garanties d'assurances .",
        //       ]}
        //       colxs={24}
        //       collg={24}
        //     />
        //   </Row>
        // )
      }
    </Col>
  );
};

export default Paiement;
